import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = '예';
lang.GLOBAL_LABEL_NO = '아니';
lang.GLOBAL_LABEL_DO_NOT_KNOW = '모름';
lang.GLOBAL_LABEL_NEXT = '다음';
lang.GLOBAL_LABEL_BACK = '뒤';
lang.GLOBAL_LABEL_NONE = '없음';
lang.GLOBAL_LABEL_SUBMIT = '제출';
lang.GLOBAL_FORMVALIDATION_REQUIRED = '필수';
lang.GLOBAL_FORMVALIDATION_EMAIL = '유효한 이메일 주소를 입력하세요';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = '안녕하세요, Codi에 오신 것을 환영합니다';
lang.MAINPAGE_PAGE_TEXT = '아래 옵션 중에서 선택하십시오 :';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = '답변 설문';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = '비디오 지침보기';
lang.MAINPAGE_PAGE_TEST_IMAGE = '테스트 이미지 업로드';
lang.GENERALINFORMATION_PAGE_TITLE = '일반 정보';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = '당신의 성별은 무엇입니까?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = '남성';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = '여자';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = '무답';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = '당신의 연령대를 알려주세요.';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = '나는 말하고 싶지 않다';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = '건강은 어떻습니까?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = '나는 매우 적합하고 거의 아프지 않습니다';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = '나는 평균보다 더 건강하고 건강하다';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = '나는 평균보다 덜 건강하고 건강하다';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = '나는 전혀 건강하지 않다';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = '다음 중 귀하에게 적용 / 적용되는 것은 무엇입니까?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = '해당없음';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = '약간의 심장질환';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = '만성 심장 질환';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = '가벼운 천식과 같은 가벼운 폐 문제';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = '만성 폐 문제';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = '경증 또는 경계선 당뇨병';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = '만성 당뇨병';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = '화학 요법으로 최근 (지난 5 년) 암 치료';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = '화학 요법으로 과거 암 치료 (5 년 전)';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = '신장 기능 감소';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = '만성 간 질환';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = '면역 억제제를 복용합니다';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = '임신 중';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = '작년에 독감 예방 주사를 맞았습니까?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = '위치 및 여행';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = '당신은 지금 어디에 살고 있습니까?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = '우편 번호 / 우편 번호의 처음 세 글자 또는 숫자 만 입력하십시오.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = '2020 년 1 월 1 일 이후 다른 국가로 여행 한 적이 있습니까?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = '예, 락다운 이전';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = '예, 락다운 동안';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = '예, 락다운 종료 후';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = '정확히 3자를 입력하십시오';
lang.TAKEPHOTO_PAGE_HEADER = '사진 페이지 찍으세요.';
lang.TAKEPHOTO_PAGE_BTN = '사진을 찍다';
lang.TRAVELCONTINUED_PAGE_TITLE = '여행 계속';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = '2020 년 1 월 1 일 이후 어느 국가를 방문하셨습니까? (여러 국가를 방문한 경우 COVID-19에 걸렸거나 걸릴 위험이 가장 높은 국가를 선택하십시오)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = '방문하였다면 입국 날짜는?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = '출국날짜는 언제입니까?';
lang.EXPOSURERISK_PAGE_TITLE = '노출 위험';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = '나라가 잠긴 동안 일하러 갔습니까?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = '예,하지만 저는 하루에 소수의 사람들과 교류했습니다';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = '예, 저는 매일 많은 사람들과 교류했습니다';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = '예, 저는 의료 환경에서 매일 많은 사람들 / 환자와 대화했습니다.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = '집 밖에서 COVID-19를 잡는 것을 피하기 위해 무엇을 했습니까?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = '나는 항상 사람들로부터 6 피트 / 2m 거리를 유지했습니다.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = '나는 항상 마스크 / 페이스 커버를 입었다.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = '나는 다른 사람들과 함께 실내 / 차 안에서 30 분 이상을 보냈다.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = '나는 자주 손을 씻고 거의 얼굴을 만지지 않았습니다.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = '당신의 집 밖에서 발병 후 그 이후에 누군가와 시간을 보냈습니까?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'COVID-19 확인 (긍정적 진단)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = '가능한 COVID-19 증상';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = '해당없음';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = '몇 사람과 함께 살고 있습니까? (예 : 2를 입력하십시오)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = '집에있는 사람 중에 COVID-19가 있었을 것이라고 생각하십니까?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = '네, 아마도';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = '예, 확실히 (긍정적 인 테스트 결과)';
lang.ILLHOUSEMATES_PAGE_TITLE = '병가';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'COVID-19 증상이있을 수있는 사람은 몇 명입니까? 숫자를 입력하십시오 (예 : 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = '집에있는 첫 사람이 언제 아프게 되었습니까?';
lang.WEREYOUILL_PAGE_TITLE = '당신은 아픈 적이 있습니까?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = '2020 년 1 월 이후로 아픈적이 있습니까? (아니오라고하면 증상에 대한 질문을받지 않습니다)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = '2020 년 1 월 이후 전혀 아프지 않았음';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = '아프기 시작';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = '현재 꽤 아픔';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = '매우 아픔';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = '여전히 아프지만 괜찮은 수준';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = '회복하고 있었지만 이제는 더 나 빠짐';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = '예,하지만 완전히 회복 된 느낌입니다';
lang.YOURSYMPTOMS_PAGE_TITLE = '당신의 증상';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = '다음 중 어떤 증상이 있었습니까?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = '증상 없음';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = '정말 피곤해';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = '두통';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = '통증 관절 및 / 또는 근육';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = '인후염';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = '혼잡 코';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = '콧물';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = '마른 기침';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = '가래로 기침';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = '재채기';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = '발열';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = '오한';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = '맛 / 냄새의 상실';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = '숨가쁨';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = '위 장애 / 설사';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = '구토 / 메스꺼움';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = '피부 발진';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = '얼마나 힘들었습니까, 아니면 숨쉬기 쉬웠습니까? (2 = 가벼운 호흡 곤란, 8 = 심한 호흡 곤란)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = '당신은 얼마나 나쁘거나 전반적인 기분이 어떻습니까? (2 = 약간 아프다, 8 = 심각하게 아프다 / 병원에 갔다)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'COVID19에 대해 얼마나 염려 했습니까? (2 = 약간 불안, 8 = 매우 불안)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = '언제 처음 증상을 경험 했습니까?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = '얼마나 오래 아프거나 증상이 있습니까?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = '며칠 이하';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = '일주일 미만';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = '1-2 주';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = '2-3 주';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = '3 주 이상';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = '아직도 아픔';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = '코로나 바이러스 / COVID-19에 감염되었다고 생각하십니까?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = '아니오';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = '~ 25 % 확신';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = '25%~ 50 % 확신';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = '꽤 확신합니다 (~ 75 % 확신)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = '절대적으로 확신 (~ 90 % + 확실한)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = '예, 100 % : 긍정적으로 테스트했습니다';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = '예, 100 % : 병원에서 치료를 받았습니다';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = '예, 100 % : 집중 치료를 받았습니다';
lang.COVIDTESTING_PAGE_TITLE = 'COVID 테스트';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'COVID-19를 가지고 있는지 확인하기 위해 항체 검사를 했습니까?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = '아니요, 항체 검사를하지 않았습니다';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = '아니요,하지만 다른 유형의 테스트를했습니다';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = '예, 긍정적으로 테스트했습니다';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = '예,하지만 부정적인 테스트를 거쳤습니다';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = '예,하지만 결과를 모르겠습니다';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = '시험 날짜는 언제입니까?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'COVID-19가 있는지 / 있었는지 확인하기 위해 어떤 종류의 항체 검사를 했습니까?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = '빠른 항체 혈액 검사 (측면 흐름 면역 분석)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = '실험실 항체 검사를 위해 보낸 혈액';
lang.ANTIBODYTEST_PAGE_TITLE = '항체 검사';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = '테스트 결과는 어땠습니까?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'C 만';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C와 IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C와 IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM 및 IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'IgG 만 (C 없음)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'IgM 만 (C 없음)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'IgG 및 IgM 만 (C 없음)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = '없음, 줄 없음';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = '위의 어느 것도';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = '테스트 카세트가 화면을 가득 채울 수 있도록 조명을 균일하게하여 위에서 직접 찍은 항체 테스트 사진을 업로드하십시오 (아래 그림 참조).';
lang.OTHERTESTS_PAGE_TITLE = '다른 테스트';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = '실험실 항체 검사를 위해 혈액을 보냈습니까?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'PCR을 위해 가래 / 비강 / 목구멍 면봉을 했습니까?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = '바이러스 성 항원 검사를 했습니까?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'X-ray / CT 검사를 받았습니까?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = '의사의 진단을 받았습니까?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = '하지 않았다';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = '부정적인 결과';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = '긍정적 인 결과';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = '알 수없는 결과';
lang.EMAILCONSENT_PAGE_TITLE = '마지막 질문';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = '완전히 회복했는지, 혈장을 기증했는지, 코로나 바이러스에 면역이 있는지 확인하기 위해 연구, 테스트 프로그램 또는 시험에 대해 알고 싶으십니까?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = '관심이 있으시면 더 많은 정보를 보내주세요.';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = '관심이 있지만 정보가 없습니다.';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = '아니요, 관심이 없습니다';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = '아래에 이메일 주소를 남겨주세요';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'آره';
lang.GLOBAL_LABEL_NO = 'نه';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'نمی دانم / نمی توانم به خاطر بسپارم';
lang.GLOBAL_LABEL_NEXT = 'بعد';
lang.GLOBAL_LABEL_BACK = 'قبل';
lang.GLOBAL_LABEL_NONE = 'هيچ كدام';
lang.GLOBAL_LABEL_SUBMIT = 'ثبت';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'ضروری';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'لطفا یک آدرس ایمیل معتبر وارد کنید';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'سلام به کدی خوش آمدید';
lang.MAINPAGE_PAGE_TEXT = 'لطفاً از گزینه های زیر استفاده کنید:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'پاسخ به پرسشنامه';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'دستورالعمل های ویدیویی را تماشا کنید';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'بارگیری تصویر آزمون';
lang.GENERALINFORMATION_PAGE_TITLE = 'اطلاعات کلی';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'در بدو تولد چه جنسی تعیین کردید؟';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'نر';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'زن';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'دیگر / ترجیح می دهم نگویم';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'گروه سنی شما چیست؟';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'ترجیح می دهم نگویم';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'سلامتی شما چطور است؟';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'من بسیار مناسب و به ندرت بیمار هستم';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'من از متوسط ​​متوسط ​​بهتر و سالم تر هستم';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'من از حد متوسط ​​کمتر سالم و سالم هستم';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'من اصلاً خیلی سالم نیستم';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'کدام یک از موارد زیر را دارید / برای شما کاربرد دارد؟';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'هیچ یک از موارد زیر';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'وضعیت خفیف قلب';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'وضعیت مزمن قلب';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'مشکل ریه خفیف مانند آسم خفیف';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'مشکلات مزمن ریه';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'خفیف یا مرزی دیابت';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'دیابت مزمن';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'اخیراً (5 سال گذشته) درمان سرطان با شیمی درمانی';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'درمان سرطان گذشته (بیش از 5 سال پیش) با شیمی درمانی';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'عملکرد کلیه کاهش می یابد';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'بیماری مزمن کبد';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'داروهای سرکوب کننده سیستم ایمنی مصرف می کنم';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'من حامله ام';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'آیا در سال گذشته دچار آنفولانزا شدید؟';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'مکان و سفر';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'الان کجا زندگی می کنی؟';
lang.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT = 'Please enter which state you live in.';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'لطفا فقط سه حرف یا شماره اول کد پستی / کد پستی خود را وارد کنید.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'آیا از اول ژانویه سال 2020 به کشورهای دیگری سفر کرده اید؟';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'بله ، قبل از اینکه آنها در "قفل کردن" قرار بگیرند';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'بله ، در حالی که آنها در "قفل کردن" بودند';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'بله ، بعد از بیرون آمدن از "قفل"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'لطفاً دقیقاً 3 نویسه وارد کنید';
lang.TAKEPHOTO_PAGE_HEADER = 'صفحه عکس را بگیرید';
lang.TAKEPHOTO_PAGE_BTN = 'عکس گرفتن';
lang.TRAVELCONTINUED_PAGE_TITLE = 'سفر ادامه دارد';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'از اول ژانویه سال 2020 از کدام کشورها بازدید کرده اید؟ (اگر به چندین کشور رفته اید ، لطفاً یکی را انتخاب کنید که فکر می کنید COVID-19 را گرفتید یا بیشترین خطر ابتلا به آن را داشتید)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'چه تاریخی وارد آن کشور شدید؟';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'چه تاریخی را از آن کشور خارج کردید؟';
lang.EXPOSURERISK_PAGE_TITLE = 'خطر قرار گرفتن در معرض';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'آیا در حین محاصره کشور خود به سر کار رفتید؟';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'بله ، اما من روزانه با افراد معدودی تعامل داشتم';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'بله ، و من روزانه با افراد زیادی تعامل داشتم';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'بله ، و من روزانه با بسیاری از افراد / بیماران در یک محیط بهداشت و درمان ارتباط برقرار کردم.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'چه شد که از ابتلا به COVID-19 در خارج از خانه خود جلوگیری کرد؟';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'من همیشه فاصله 6 فوت / 2 متر از مردم نگه داشته ام.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'من همیشه یک ماسک / روکش صورت می پوشیدم.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'من هرگز بیش از 30 دقیقه در داخل خانه / در وسایل نقلیه با دیگران نگذاشتم.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'من مرتباً دستانم را می شستم و بندرت صورتم را لمس می کردم.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'آیا خارج از خانه خود وقت خود را با کسی که گذراند یا زود پس از توسعه ... گذراندید؟';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'COVID-19 تأیید شد (تشخیص مثبت)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'علائم احتمالی COVID-19';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'هیچ یک از موارد فوق';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'با چند نفر زندگی می کنید؟ (لطفاً شماره ای را وارد کنید ، به عنوان مثال 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'فکر می کنید ممکن است کسی در خانه شما COVID-19 داشته باشد؟';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'بله ، احتمالاً';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'بله ، قطعاً (نتیجه تست مثبت)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Ill Housemates';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'چه تعداد از افرادی که با آنها زندگی می کنید علائم COVID-19 ممکن دارند؟ لطفاً شماره ای را وارد کنید ، به عنوان مثال 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'چه زمانی شخص اول خانه شما بیمار شد؟';
lang.WEREYOUILL_PAGE_TITLE = 'آیا تا به حال مریض بودی؟';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'آیا از ژانویه سال 2020 اصلاً احساس بیماری کرده اید؟ (اگر نه بگویید ، از علائم سؤال نخواهید شد)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'نه ، خوبم من از ژانویه سال 2020 به هیچ وجه احساس بیماری نکرده ام';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'من شروع به احساس بیماری می کنم';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'الان حالم بسیار بیمار است';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'من خیلی بیمار هستم';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'من هنوز بیمار هستم اما احساس بهتری دارم';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'من در حال بهبودی بودم ، اما اکنون دوباره احساس بدتر کردم';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'بله ، اما احساس می کنم کاملاً بهبود یافته است';
lang.YOURSYMPTOMS_PAGE_TITLE = 'علائم شما';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'کدام یک از علائم زیر را دارید؟';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'بدون علائم';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'واقعا خسته';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'سردرد';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'مفاصل اچ و عضلات';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'گلو درد';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'بینی احتقان';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'آبریزش بینی';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'سرفه خشک';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'سرفه با خلط';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'عطسه';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'تب';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'لرز';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'از دست دادن حس طعم / بو';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'تنگی نفس';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'اختلال در معده / اسهال';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'استفراغ / حالت تهوع';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'بثورات پوستی';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'چقدر سخت شدی یا نفس می کشی؟ (2 = تنگی نفس ، 8 = تنگی شدید)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'چقدر بد شدی یا کلی احساس کردی؟ (2 = فقط اندکی بیمار ، 8 = به شدت بیمار / به بیمارستان رفت)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'در مورد COVID19 چقدر مضطرب هستید؟ (2 = کمی مضطرب ، 8 = بسیار مضطرب)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'چه زمانی علائم را تجربه کردید؟';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'چه مدت احساس بیماری کرده اید یا علائم آن وجود دارد؟';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'چند روز یا کمتر';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'کمتر از یک هفته';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'یک تا دو هفته';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'دو تا سه هفته';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'بیش از سه هفته';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'من هنوز مریض هستم!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'آیا فکر می کنید به Coronavirus / COVID-19 آلوده شده اید؟';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'به هیچ وجه';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'شانس اندک (25 ~ مطمئن)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'یک شانس مناسب (50 ~ مطمئن)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'بسیار مطمئن (75 ~ مطمئن)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'کاملاً متقاعد شده (90٪ + + مطمئن)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'بله ، 100٪: تست مثبت کردم';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'بله ، 100٪: من در بیمارستان تحت معالجه قرار گرفتم';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'بله ، 100٪: من در مراقبت های ویژه تحت معالجه قرار گرفتم';
lang.COVIDTESTING_PAGE_TITLE = 'تست COVID';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'آیا آزمایش آنتی بادی را انجام داده اید تا بررسی کنید که COVID-19 داشته اید؟';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'نه ، من آزمایش آنتی بادی را انجام نداده ام';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'نه ، اما من نوع دیگری از آزمون را انجام دادم';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'بله ، و من مثبت تست کردم';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'بله ، اما منفی را آزمایش کردم';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'بله ، اما من نتیجه را نمی دانم';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'آزمون را در چه تاریخی انجام دادید؟';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'چه نوع آزمایش آنتی بادی را انجام دادید تا ببینید COVID-19 را دارید یا داشتید؟';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'آزمایش خون سریع آنتی بادی (آزمایش ایمنی جریان جانبی)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'خون برای آزمایش آنتی بادی آزمایشگاهی ارسال شد';
lang.ANTIBODYTEST_PAGE_TITLE = 'تست آنتی بادی';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'نتیجه تست شما چگونه بود؟';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'فقط C';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C و IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C و IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C ، IgM و IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'فقط IgG (بدون C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'فقط IgM (بدون C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'فقط IgG و IgM (بدون C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'هیچ ، بدون خط';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'هیچ یک از موارد فوق';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'لطفاً عکسی از آزمایش آنتی بادی را بگیرید که مستقیماً از بالا گرفته شده است ، حتی با نورپردازی به طوری که کاست آزمایش صفحه را پر کند (مانند نمونه زیر).';
lang.OTHERTESTS_PAGE_TITLE = 'تست های دیگر';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'آیا برای آزمایش آنتی بادی آزمایشگاهی خون ارسال کردید؟';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'آیا شما برای PCR یک خلط / بینی / گلو استفاده کرده اید؟';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'آیا آزمایش آنتی ژن ویروسی انجام داده اید؟';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'آیا آزمایش اسکن اشعه X / CT داشته اید؟';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'آیا تشخیص دکتر داشتید؟';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'انجام نداد';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'نتیجه منفی';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'نتیجه مثبت';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'نتیجه ناشناخته';
lang.EMAILCONSENT_PAGE_TITLE = 'سوال نهایی';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'آیا شما علاقه مند به دانستن هرگونه مطالعات ، برنامه های آزمایش یا آزمایشات هستید تا در صورت بهبودی کامل ، اهداء پلاسما به شما تأیید کنید یا ممکن است در مقابل ویروس کرونا مصون باشید؟';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'علاقه مند ، وقتی در مورد آن می شنوید اطلاعات بیشتری برای من ارسال کنید';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'علاقه مند است ، اما هیچ اطلاعاتی لطفاً';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'نه ، علاقه مند نیست';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'لطفا آدرس ایمیل خود را در زیر بگذارید';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

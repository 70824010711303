import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Так';
lang.GLOBAL_LABEL_NO = 'Не';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'Я не ведаю / не памятаю';
lang.GLOBAL_LABEL_NEXT = 'Далей/Наступны';
lang.GLOBAL_LABEL_BACK = 'Вярнуцца';
lang.GLOBAL_LABEL_NONE = 'Не';
lang.GLOBAL_LABEL_SUBMIT = 'Адправіць';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Абавязковы';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Калі ласка, увядзіце сапраўдны адрас электроннай пошты';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Прывітанне, Сардэчна запрашаем ў Кодзі';
lang.MAINPAGE_PAGE_TEXT = 'Калі ласка, выберыце адзін з варыянтаў:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Запоўніце анкету';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Паглядзіце відэа інструкцыі';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Загрузіце тэставы малюнак';
lang.GENERALINFORMATION_PAGE_TITLE = 'Агульная інфармацыя';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Які ваш пол пры нараджэнні?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Мужчынскі';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Жаночы';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Іншае / Лiчу за лепшае не адказваць';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Якая ваша ўзроставая група?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Лiчу за лепшае не адказваць';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Як ваша здароўе?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'У мяне добрае здароўе і я рэдка хварэю';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Маё здароўе лепш сярэдняга';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Маё здароўе горш сярэдняга';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'Я зусім не здаровы';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Што з пералічанага вы маеце?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Нічога з пералічанага';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Лёгкае захворванне сэрца';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Хранічнае захворванне сэрца';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Лёгкая праблема з лёгкімі, як лёгкая астма';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Хранічныя праблемы з лёгкімі';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Лёгкі або памежны дыябет';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Хранічны дыябет';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Нядаўнія (апошнія 5 гадоў) лячэнне рака хіміятэрапіяй';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Мiнулае лячэнне рака (больш за 5 гадоў таму) хіміятэрапіяй';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Зніжэнне функцыі нырак';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Хранічная хвароба печані';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Я прымаю супрацьзапаленчыя прэпараты';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'Я цяжарная';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Ці атрымлівалі вы грып у мінулым годзе?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Размяшчэнне і падарожжы';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Дзе вы жывяце зараз?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Калі ласка, увядзіце толькі першыя тры літары альбо лічбы вашага паштовага індэкса.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Вы ездзілі ў якія-небудзь іншыя краіны з 1 студзеня 2020 года?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Так, раней, чым яны ўсталявалі рэжым закрытых межаў';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Так, у той час калi яны знаходзіліся ў рэжыме закрытых межаў';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Так, пасля выхаду з рэжыма закрытых межаў';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Калі ласка, увядзіце роўна 3 знакі';
lang.TAKEPHOTO_PAGE_HEADER = 'Зрабіце фота старонкi';
lang.TAKEPHOTO_PAGE_BTN = 'Зрабіце фота';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Падарожжа працягвалася';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Якія краіны вы наведалі з 1 студзеня 2020 года? (Калі вы ездзілі ў некалькі краін, калі ласка, выберыце тую, дзе вы лічыце, што вы маглі б падхапіць COVID-19 альбо максімальна рызыкуеце падхапіць яе)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'У якую дату вы ўвайшлі ў гэтую краіну?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'На якую дату вы выехалі з гэтай краіны?';
lang.EXPOSURERISK_PAGE_TITLE = 'Рызыка ўздзеяння';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Вы хадзілі на працу падчас карантiну?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Так, але я ўзаемадзейнічаў толькi з некалькімі людзьмі ў дзень';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Так, і я ўзаемадзейнічаў з многімі людзьмі ў дзень';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Так, і я ўзаемадзейнічаў з многімі людзьмі / пацыентамі ў дзень у медыцынскіх умовах.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'Якія меры вы прынялі, каб не падхапіць COVID-19 на вуліцы';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Я заўсёды трымаў дыстанцыю ад людзей на 6 футаў / 2 м.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Я заўсёды насіў маску.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Я ніколі не праводзіў больш за 30 хвілін у памяшканні / у грамадскім транспарце.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Я часта мыў рукі і рэдка дакранаўся да твару.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Па-за домам вы праводзілі час з кімсьці, хто меў альбо нядаўна вылечыўся ад COVID-19?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Пацверджаны COVID-19 (Станоўчы дыягназ)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Магчымыя сімптомы COVID-19';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Нічога з вышэйпералічанага';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Колькі людзей жыве з вамі (Увядзіце нумар, напрыклад, 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Як вы думаеце, хто-небудзь у вашым доме, магчыма, меў COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Так, магчыма';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Так, абавязкова (станоўчы вынік тэсту)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Хворыя суседзі па пакоі';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Колькі людзей, з якімі вы жывяце, мелі магчымыя сімптомы COVID-19? Увядзіце нумар, напрыклад, 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Калі першы чалавек у вашым доме захварэў?';
lang.WEREYOUILL_PAGE_TITLE = 'Вы калі-небудзь хварэлі?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Вы адчувалі сябе дрэнна са студзеня 2020 года? (Калі вы скажаце "не", вас не спытаюць пра сімптомы)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'Не, я ў парадку. Я наогул не адчуваў сябе дрэнна са студзеня 2020 года';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Я адчуваю, што пачынаю хвараць';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Я адчуваю сябе дрэнна на дадзены момант';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Мне вельмі дрэнна';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Я ўсё яшчэ хварэю, але адчуваю сябе лепш';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Я аднаўляўся, але цяпер зноў адчуваю сябе горш';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Так, але я адчуваю сябе цалкам выздараўленым';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Вашы сімптомы';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Які з пералічаных сімптомаў у вас ёсць?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Ніякіх сімптомаў';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Адчуваю сябе стомленым';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Галаўны боль';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Баляць суставы і / або мышцы';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Боль ў горле';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Закладзены нос';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Насмарк';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Сухі кашаль';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Кашаль з макроццем';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Чханне';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Гарачка';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Дрыжыкі';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Страта густу / паху';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Абцяжаранае дыханне';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Парушэнне страўніка / дыярэя';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Ваніты / млоснасць';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Скурная сып';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Як цяжка вам было, ці вам здаецца, што дыхаеце? (2 = лёгкая дыхавіца, 8 = цяжкая дыхавіца)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Як дрэнна ў вас, ці вы адчуваеце сябе ў цэлым? (2 = толькі слаба хворы, 8 = цяжка хворы / пайшоў у бальніцу)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'Наколькі трывожныя былі вы пра COVID19? (2 = трывожна, 8 = вельмі трывожна)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'Калі вы ўпершыню адчулі сімптомы?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Як доўга вы адчувалі сімптомы?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Некалькі дзён ці менш';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Менш аднаго тыдня';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Адзін-два тыдні';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Два-тры тыдні';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Больш за тры тыдні';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Я ўсё яшчэ хварэю!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Вы думаеце, што заразіліся каранавірусам / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'Ніякім чынам';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Невялікі шанец (упэўнены ~ 25%)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Сярэдні шанец (упэўнены ~ 50%)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Даволі ўпэўнены (упэўнены ~ 75%)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Абсалютна перакананы (~ 90% + упэўнены)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Так, 100%: тэст станоўчы';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Так, 100%: я лячыўся ў бальніцы';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Так, 100%: мяне лячылі ў рэанімацыі';
lang.COVIDTESTING_PAGE_TITLE = 'Тэставанне на COVID-19';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Вы зрабілі тэст на антыцелы, каб праверыць, ці ёсць у вас COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'Не, я не рабіў тэст на антыцелы';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'Не, я зрабіла тэст іншага тыпу';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Так, і тэст станоўчы';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Так, тэст адмоўны';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Так, але я не ведаю выніку';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'Калi вы зрабілі тэст?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'Які тэст на антыцелы вы зрабілі, каб даведацца, ці ёсць у вас COVID-19?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Хуткі аналіз крыві на антыцелы (імуналагічны аналіз з бакавым патокам)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Адправілі кроў на лабараторны тэст на антыцелы';
lang.ANTIBODYTEST_PAGE_TITLE = 'Тэст на антыцелы';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Як выглядаў ваш тэст?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'C толькі';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'З і IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'З і IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'З, IgM і IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Толькі IgG (без З)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Толькі IgM (без З)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Толькі IgG і IgM (без З)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Няма, ніякіх радкоў';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Нічога з вышэйпералічанага';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Калі ласка, загрузіце фатаграфію тэсту на антыцелы, зробленага прама зверху, з раўнамерным асвятленнем, каб тэставая касета запаўняла экран (напрыклад, ніжэй).';
lang.OTHERTESTS_PAGE_TITLE = 'Іншыя тэсты';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Вы адправілі кроў на лабараторны тэст на антыцелы?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Вы зрабілі мазок на мокроту / нос / горла для ПЦР?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Вы зрабілі тэст на вірусны антыген?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Ці былі ў вас рэнтгеналагічныя / КТ тэсты?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'У вас быў дыягназ доктара?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'Не рабіў';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Адмоўны вынік';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Станоўчы вынік';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Невядомы вынік';
lang.EMAILCONSENT_PAGE_TITLE = 'Заключнае пытанне';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Вам бы цікава даведацца пра якія-небудзь даследаванні, праграмы тэсціравання або выпрабаванні, якія дапамогуць пацвердзіць, калі вы поўнасцю вылечыліся, здаць плазму або вы можаце быць застрахаваны ад віруса Corona?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Зацікаўлены, дашліце мне больш інфармацыі';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Зацікаўлены, але не дасылайце мне ніякай інфармацыі, калі ласка';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'Не, не цікавіць';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Калі ласка, пакіньце свой электронны адрас ніжэй';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

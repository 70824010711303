import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Ja';
lang.GLOBAL_LABEL_NO = 'Nee';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'Ik weet het niet / kan het me niet herinneren';
lang.GLOBAL_LABEL_NEXT = 'De volgende';
lang.GLOBAL_LABEL_BACK = 'Terug';
lang.GLOBAL_LABEL_NONE = 'Geen';
lang.GLOBAL_LABEL_SUBMIT = 'Indienen';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Verplicht';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Vul een geldig e-mailadres in';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Hallo, welkom bij Codi';
lang.MAINPAGE_PAGE_TEXT = 'Maak een keuze uit de onderstaande opties:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Vragenlijst beantwoorden';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Bekijk video-instructies';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Testafbeelding uploaden';
lang.GENERALINFORMATION_PAGE_TITLE = 'Algemene informatie';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Welk geslacht kreeg je toegewezen bij de geboorte?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Mannetje';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Vrouw';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Anders / Zeg ik liever niet';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Wat is uw leeftijdsgroep?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Ik zeg het liever niet';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Hoe is het met je gezondheid?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Ik ben erg fit en zelden ziek';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Ik ben fitter en gezonder dan gemiddeld';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Ik ben minder fit en gezond dan gemiddeld';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'Ik ben helemaal niet erg gezond';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Welke van de volgende zaken heb je / solliciteer je?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Geen van de volgende';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Milde hartaandoening';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Chronische hartaandoening';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Milde longproblemen zoals milde astma';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Chronische longproblemen';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Milde of borderline diabetes';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Chronische diabetes';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Recente (laatste 5 jaar) Kankerbehandeling met chemo';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Verleden behandeling van kanker (meer dan 5 jaar geleden) met chemo';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Verminderde nierfunctie';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Chronische leverziekte';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Ik gebruik immunosuppressiva';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'ik ben zwanger';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Heb je het afgelopen jaar een griepprik gekregen?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Locatie en reizen';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Waar woon je nu?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Voer alleen de eerste drie letters of cijfers van uw postcode / postcode in.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Ben je sinds 1 januari 2020 naar andere landen gereisd?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Ja, voordat ze in "lockdown" zaten';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Ja, terwijl ze in "lockdown" zaten';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Ja, nadat ze uit "lockdown" kwamen';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Voer exact 3 tekens in';
lang.TAKEPHOTO_PAGE_HEADER = 'Neem fotopagina';
lang.TAKEPHOTO_PAGE_BTN = 'Foto nemen';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Reizen vervolg';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Welke landen heb je bezocht sinds 1 januari 2020? (Als u naar meerdere landen bent gegaan, kies dan degene waarvan u denkt dat u COVID-19 had kunnen vangen of het grootste risico liep om het te vangen)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'Op welke datum bent u dat land binnengekomen?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'Op welke datum verliet u dat land?';
lang.EXPOSURERISK_PAGE_TITLE = 'Blootstellingsrisico';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Bent u aan het werk gegaan tijdens de afsluiting van uw land?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Ja, maar ik heb met weinig mensen per dag contact gehad';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Ja, en ik heb met veel mensen per dag contact gehad';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Ja, en ik heb dagelijks met veel mensen / patiënten contact gehad in een gezondheidszorgomgeving.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'Wat heb je vermeden om COVID-19 buiten je huis te vangen?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Ik hield altijd 6 ft / 2 m afstand van mensen.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Ik droeg altijd een masker / gezichtsbedekking.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Ik heb nooit meer dan 30 minuten binnenshuis / in voertuigen met anderen doorgebracht.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Ik waste regelmatig mijn handen en raakte zelden mijn gezicht aan.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Heb je buiten je huis tijd doorgebracht met iemand die zich of kort daarna had ontwikkeld ...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Bevestigde COVID-19 (positieve diagnose)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Mogelijke COVID-19-symptomen';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Geen van de bovengenoemde';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Met hoeveel mensen woon je? (Voer een nummer in, bijvoorbeeld 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Denk je dat iemand bij jou thuis COVID-19 heeft gehad?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Ja, mogelijk';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Ja, zeker (positief testresultaat)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Zieke huisgenoten';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Hoeveel mensen met wie u samenleeft, hebben mogelijke COVID-19-symptomen gehad? Voer een nummer in, b.v. 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Wanneer is de eerste persoon in uw huis ziek geworden?';
lang.WEREYOUILL_PAGE_TITLE = 'Ben je ooit ziek geweest?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Ben je sinds januari 2020 helemaal ziek geweest? (Als u nee zegt, wordt u niet gevraagd naar symptomen)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'Nee, ik ben oke. Ik voel me sinds januari 2020 helemaal niet ziek meer';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Ik begin me ziek te voelen';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Ik voel me nu behoorlijk ziek';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Ik voel me erg ziek';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Ik ben nog steeds ziek, maar voel me beter';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Ik herstelde, maar voelde me nu weer slechter';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Ja, maar ik voel me volledig hersteld';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Uw symptomen';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Welke van de volgende symptomen had / heeft u?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Geen symptomen';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Echt moe';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Hoofdpijn';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Achy gewrichten en / of spieren';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Een zere keel';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Verstopte neus';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Loopneus';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Een droge hoest';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Een hoest met sputum';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Niezen';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Koorts';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Rillingen';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Verlies van smaak / geur';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Kortademig';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Maagstoornissen / diarree';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Braken / misselijkheid';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Huiduitslag';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Hoe moeilijk vond je het, of vind je het om te ademen? (2 = lichte kortademigheid, 8 = ernstige kortademigheid)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Hoe erg voelde je je, of voel je je in het algemeen? (2 = slechts licht ziek, 8 = ernstig ziek / ging naar het ziekenhuis)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'Hoe bezorgd was / bent u over COVID19? (2 = een beetje angstig, 8 = erg angstig)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'Wanneer heb je voor het eerst symptomen ervaren?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Hoelang voelde u zich ziek / had u symptomen?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Een paar dagen of minder';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Minder dan een week';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Een tot twee weken';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Twee tot drie weken';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Meer dan drie weken';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Ik ben nog steeds ziek!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Dacht / denk je dat je besmet was met Coronavirus / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'Echt niet';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Een kleine kans (~ 25% zeker)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Een redelijke kans (~ 50% zeker)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Vrij zeker (~ 75% zeker)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absoluut overtuigd (~ 90% + zeker)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Ja, 100%: ik heb positief getest';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Ja, 100%: ik werd in het ziekenhuis behandeld';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Ja, 100%: ik werd behandeld op de intensive care';
lang.COVIDTESTING_PAGE_TITLE = 'COVID-testen';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Heeft u een antilichaamtest gedaan om te controleren of u COVID-19 heeft / had?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'Nee, ik heb geen antilichaamtest gedaan';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'Nee, maar ik heb een ander type test gedaan';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Ja, en ik heb positief getest';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Ja, maar ik heb negatief getest';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Ja, maar ik weet het resultaat niet';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'Op welke datum heb je de test gedaan?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'Wat voor soort antilichaamtest heb je gedaan om te zien of je COVID-19 hebt / had?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Snelle antilichaambloedtest (laterale-stroomimmunoassay)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Verzonden bloed voor laboratoriumantilichaamtest';
lang.ANTIBODYTEST_PAGE_TITLE = 'Antilichaamtest';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Hoe zag uw testresultaat eruit?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'Alleen C';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C en IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C en IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM en IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Alleen IgG (geen C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Alleen IgM (geen C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Alleen IgG en IgM (geen C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Geen, geen lijnen';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Geen van de bovengenoemde';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Upload een foto van de antilichaamtest die direct van boven is genomen, met gelijkmatige verlichting zodat de testcassette het scherm vult (zoals hieronder).';
lang.OTHERTESTS_PAGE_TITLE = 'Get the latest information';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = ' ';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Send feedback';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'History';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Saved';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Community';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'Niet gedaan';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Negatief resultaat';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Positief resultaat';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Onbekend resultaat';
lang.EMAILCONSENT_PAGE_TITLE = 'Laatste vraag';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Zou u geïnteresseerd zijn in informatie over onderzoeken, testprogramma\'s of proeven, om te helpen bevestigen of u volledig hersteld bent, om plasma te doneren of om immuun te zijn voor het Corona-virus?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Geïnteresseerd, stuur me meer informatie als je erover hoort';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Geïnteresseerd, maar geen informatie alstublieft';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'Nee, niet geïnteresseerd';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Laat hieronder uw e-mailadres achter';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Yes';
lang.GLOBAL_LABEL_NO = 'No';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'I don\'t know / can\'t remember';
lang.GLOBAL_LABEL_NEXT = 'Next';
lang.GLOBAL_LABEL_BACK = 'Back';
lang.GLOBAL_LABEL_NONE = 'None';
lang.GLOBAL_LABEL_SUBMIT = 'Submit';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Required';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Please enter a valid email address';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Hi, Welcome to Codi Covi-Clear';
lang.MAINPAGE_PAGE_TEXT = 'Please select from the options below:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Answer Questionnaire';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Watch Video Instructions';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Upload Test Image';
lang.GENERALINFORMATION_PAGE_TITLE = 'General Information ~ 10 min to complete quiz';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'What sex were you assigned at birth?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Male';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Female';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Other / Prefer not to say';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'What is your age group?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'I prefer not to say';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'How is your health?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'I am very fit and rarely ill';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'I am fitter and healthier than average';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'I am less fit and healthy than average';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'I\'m not very healthy at all';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Which of the following do you have/apply to you?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'None of the following';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Mild heart condition';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Chronic heart condition';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Mild lung problem like mild asthma';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Chronic lung problems';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Mild or borderline Diabetes';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Chronic Diabetes';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Recent (last 5 years) Cancer treatment with chemo';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Past Cancer treatment (over 5 years ago) with chemo';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Reduced kidney function';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Chronic liver disease';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'I take immunosuppressant drugs';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'I am pregnant';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Have you received a flu shot since January 2020?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Location and Travel ~ 8 min to complete quiz';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Where are you living right now?';
lang.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT = 'Please enter which state you live in.';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Please enter only the first three letters or numbers of your postcode/zipcode.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Have you travelled to any other countries since January 1st 2020?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Yes, before they were in "lockdown"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Yes, while they were in "lockdown"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Yes, after they came out of "lockdown"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Please enter exactly 3 characters';
lang.TAKEPHOTO_PAGE_HEADER = 'Take Photo Page';
lang.TAKEPHOTO_PAGE_BTN = 'Take Photo';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Travel Continued ~ 7 min to complete quiz';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Which countries have you visited since January 1st 2020? (If you went to multiple countries please pick the one where you think you could have caught COVID-19 or had the highest risk of catching it)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'What date did you enter that country?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'What date did you leave that country?';
lang.EXPOSURERISK_PAGE_TITLE = 'Exposure risk ~ 7 min to complete quiz';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Did you go to work during your country\'s lockdown?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Yes, but I interacted with few people per day';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Yes, and I interacted with many people per day';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Yes, and I interacted with many people/patients per day in a healthcare setting.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'What did you to avoid catching COVID-19 outside your home?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'I always kept 6 ft/2 m distance from people.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'I always wore a mask/face covering.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'I never spent more than 30 minutes indoors/in vehicles with others.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'I frequently washed my hands and rarely touched my face.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Outside your home did you spend time with anyone who had or soon after developed...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Confirmed COVID-19 (Positive diagnosis)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Possible COVID-19 symptoms';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'None of the above';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'How many people do you live with? (Please type a number, e.g. 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Do you think anyone in your home might have had COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Yes, possibly';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Yes, definitely (Positive test result)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Ill Housemates ~ 6 min to complete quiz';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'How many people who you live with have had possible COVID-19 symptoms? Please type a number, e.g. 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'When did the first person in your house become ill?';
lang.WEREYOUILL_PAGE_TITLE = 'Were you ever ill? ~ 6 min to complete quiz';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Have you felt ill at all since January 2020? (If you say no, you won\'t be asked about symptoms)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'No, I\'m fine. I haven\'t felt ill at all since January 2020';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'I\'m beginning to feel ill';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'I\'m now feeling quite ill';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'I\'m feeling very ill';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'I\'m still ill but feeling better';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'I was recovering, but now feeling worse again';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Yes, but I feel fully recovered';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Your Symptoms ~ 4 min to complete quiz';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Which of the following symptoms did/do you have?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'No symptoms';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Really tired';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'A headache';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Achy joints and/or muscles';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'A sore throat';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Congested nose';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Runny nose';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'A dry cough';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'A cough with sputum';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Sneezing';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Fever';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Chills';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Loss of sense of taste/smell';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Short of breath';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Stomach disturbance/diarrhoea';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Vomiting/nausea';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Skin rash';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'How hard did you, or do you find it to breathe? (2 = mild shortness of breath, 8 = severe shortness of breath)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'How bad did you, or do you feel overall? (2 = only slightly ill, 8 = seriously ill/went to the hospital)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'How anxious were/are you about COVID19? (2 = a little anxious, 8 = very anxious)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'When did you first experience symptoms?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'How long did you feel ill/have symptoms for?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'A few days or less';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Less than one week';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'One to two weeks';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Two to three weeks';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'More than three weeks';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'I\'m still ill!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Did/Do you think you were infected with Coronavirus / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'No way';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'A small chance (~25% sure)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'A reasonable chance (~50% sure)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Pretty sure (~75% sure)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absolutely convinced (~90%+ sure)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Yes, 100%: I tested positive';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Yes, 100%: I was treated in hospital';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Yes, 100%: I was treated in intensive care';
lang.COVIDTESTING_PAGE_TITLE = 'COVID testing ~ 3 min to complete quiz';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Have you done a lateral flow antibody test to check if you have/had COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'No, I\'ve not done a lateral flow antibody test, or any other COVID tests.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'No, but I\'ve done a different COVID test.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Yes, and I tested positive';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Yes, but I tested negative';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Yes, but I don\'t know the result';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'What date did you do the test?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'What kind of antibody test did you do to see if you have/had COVID-19?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Rapid Antibody blood test (Lateral flow immunoassay)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Sent blood for laboratory antibody test';
lang.ANTIBODYTEST_PAGE_TITLE = 'Antibody test ~ 2 min to complete quiz';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'What did your test result look like?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'C only';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C and IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C and IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM and IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'IgG only (no C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'IgM only (no C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'IgG and IgM only (no C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'None, no lines';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'None of the above';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Please upload a photo of the antibody test taken from directly above, with even lighting so that the test cassette fills the screen (like the one below). ';
lang.OTHERTESTS_PAGE_TITLE = 'Other tests ~ 1 min to complete quiz';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Did you send blood for a laboratory antibody test?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Have you done a Sputum/Nasal/Throat swab for PCR?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Have you done a viral Antigen test?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Have you had an X-ray/CT scans of test?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Have you had a Doctor\'s diagnosis?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'No';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Negative result';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Positive result';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Unknown result';
lang.EMAILCONSENT_PAGE_TITLE = 'Final question ~ 30 sec to complete quiz';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Would you be interested in knowing about any studies, testing programmes, or trials, to help confirm if you have fully recovered, to donate plasma, or if you may be immune to Corona Virus?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Interested, send me more information when you hear about it';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Interested, but no information please';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'No, not interested';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Please leave your email address below';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Sì';
lang.GLOBAL_LABEL_NO = 'No';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'Non lo so, non ricordo';
lang.GLOBAL_LABEL_NEXT = 'Il prossimo';
lang.GLOBAL_LABEL_BACK = 'Indietro';
lang.GLOBAL_LABEL_NONE = 'Nessuno';
lang.GLOBAL_LABEL_SUBMIT = 'Invia';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Necessario';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Si prega di inserire un indirizzo di posta elettronica valido';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Ciao, benvenuto su Codi';
lang.MAINPAGE_PAGE_TEXT = 'Seleziona tra le seguenti opzioni:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Rispondi al questionario';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Vedi il video delle istruzioni';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Carica l\'immagine di prova';
lang.GENERALINFORMATION_PAGE_TITLE = 'Informazioni Generali';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Quale sesso ti è stato assegnato alla nascita?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Maschio';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Femmina';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Altro / Preferirei non dirlo';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Fascia di età?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Preferisco non dirlo';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Come stai?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Sono molto in forma e raramente mi ammalo';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Sono più in forma e più sano del solito';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Sono meno in forma ed in salute del solito';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'Non sono molto in salute';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Quale delle seguenti opzioni hai / applica per te?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Nessuna delle seguenti';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Condizioni cardiache lievi';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Cardiopatia cronica';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Problema polmonare lieve come lieve asma';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Problemi polmonari cronici';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Diabete lieve o ai limiti della norma';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Diabete Cronico';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Recenti (ultimi 5 anni) trattamenti contro cancro con chemioterapia';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Trattamento contro il cancro in passato (più di 5 anni fa) con chemioterapia';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Ridotta funzionalità renale';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Malattia epatica cronica';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Prendo farmaci immunosoppressori';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'Sono incinta';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Hai fatto il vaccino antinfluenzale l\'anno scorso?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Luogo e viaggio';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Dove vivi adesso?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Inserisci solo le prime tre lettere o numeri del tuo codice postale.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Hai viaggiato in altri paesi dal 1 ° gennaio 2020?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Sì, prima che ci fosse il  "confinamento di emergenza"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Sì, durante il "confinamento di emergenza"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Sì, dopo essere usciti dal "confinamento di emergenza"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Inserisci esattamente 3 caratteri';
lang.TAKEPHOTO_PAGE_HEADER = 'Pagina per scattare foto';
lang.TAKEPHOTO_PAGE_BTN = 'Fare foto';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Viaggio di continuo';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Quali paesi hai visitato dal 1 ° gennaio 2020? (Se sei andato in diversi paesi, scegli quello dove pensi di aver preso il COVID-19 o dove hai avuto il rischio più alto di contrarlo)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'In che data sei entrato in quel paese?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'In che data hai lasciato quel paese?';
lang.EXPOSURERISK_PAGE_TITLE = 'Rischio di esposizione';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Sei andato a lavorare durante il confinamento di emergenza del tuo paese?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Sì, ma ho interagito con poche persone al giorno.';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Sì, ed ho interagito con molte persone al giorno.';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Sì, ed ho interagito con molte persone / pazienti al giorno in un ambiente sanitario.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'Cosa hai fatto per evitare di portare COVID-19 fuori da casa tua?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Ho sempre tenuto una distanza di 2 metri dalle persone.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Ho sempre indossato una mascherina chirurgica.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Non ho mai trascorso più di 30 minuti in interni / in veicoli con altri.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Mi lavo spesso le mani e raramente mi tocco il viso.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Fuori da casa, hai trascorso del tempo con qualcuno che ha sviluppato il contagio subito dopo ...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Confermato COVID-19 (diagnosi positiva)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Possibili sintomi di COVID-19';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Nessuno dei precedenti';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Con quante persone vivi? (Scrivi un numero, ad es. 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Pensi che qualcuno a casa tua possa aver contratto il COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Sì forse';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Sì, sicuramente (risultato del test positivo)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Coinquilini malati';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Quante persone con cui vivi hanno avuto possibili sintomi di COVID-19? (Si prega di scrivere un numero, ad es. 2)';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Quando si è ammalata la prima persona a casa?';
lang.WEREYOUILL_PAGE_TITLE = 'Sei mai stato malato?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Ti sei sentito male da gennaio 2020? (Se dici di no, non ti verrà chiesto dei sintomi)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'Non sto bene. Non mi sento male da gennaio 2020';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Sto iniziando a sentirmi male';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Adesso mi sento piuttosto male';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Mi sento molto male';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Sono ancora malato ma mi sento meglio';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Mi stavo riprendendo, ma ora mi sento di nuovo male';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Sì, ma mi sento completamente guarito.';
lang.YOURSYMPTOMS_PAGE_TITLE = 'I tuoi sintomi';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Quale dei seguenti sintomi hai / hai avuto?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Nessun sintomo';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Davvero stanco';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Mal di testa';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Dolore articolare e / o muscolare';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Gola infiammata';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Naso chiuso';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Muco nasale';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Tosse secca';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Tosse con espettorato';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Starnuti';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Febbre';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Brividi che fanno tremare';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Perdita del senso del gusto / olfatto.';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Respirazione difficoltosa';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Mal di stomaco / diarrea';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Vomito / nausea';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Orticaria o allergie cutanee';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Quanto ti è costato o è difficile respirare? (2 = lieve respiro corto, 8 = grave respiro corto)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Quanto ti sei sentito male o in generale? (2 = solo leggermente malato, 8 = gravemente malato / andato in ospedale)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'Quanto eri / sei ansioso a causa del COVID19? (2 = un po \'ansioso, 8 = molto ansioso)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'Quando hai avuto i primi sintomi?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Da quanto tempo ti senti male / hai avuto sintomi?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Pochi giorni o meno';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Meno di una settimana';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Una o due settimane';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Due o tre settimane';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Più di tre settimane';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Sono ancora malato!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Pensi di essere stato infettato da Coronavirus / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'In alcun modo';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Una leggera possibilità (~ 25% sicuro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Un\'opportunità ragionevole (~ 50% sicuro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Abbastanza sicuro (~ 75% sicuro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Assolutamente convinto (~ 90% + sicuro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Sì, 100%: sono risultato positivo';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Sì, 100%: sono stato curato in ospedale';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Sì, 100%: sono stato curato in terapia intensiva';
lang.COVIDTESTING_PAGE_TITLE = 'Test COVID';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'È stato effettuato un test anticorpale per verificare se si è / è stato contratto COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'No, non ho eseguito un test anticorpale.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'No, ma ho fatto un diverso tipo di test';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Sì e sono stato positivo';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Sì, ma ho ottenuto un risultato negativo.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Sì, ma non conosco il risultato.';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'In che data hai fatto il test?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'Che tipo di test anticorpale hai eseguito per vedere se avevi / hai COVID-19?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Esame del sangue rapido per anticorpi (immunodosaggio a flusso laterale)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Invio di sangue per analisi di anticorpi di laboratorio.';
lang.ANTIBODYTEST_PAGE_TITLE = 'Test anticorpale';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Com\'era il risultato del test?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'Solo C';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C e IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C e IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM e IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Solo IgG (no C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Solo IgM (no C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Solo IgG e IgM (senza C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Nessuna, nessuna linea';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Nessuno dei precedenti';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Carica una foto del test anticorpale presa direttamente dall\'alto, con illuminazione uniforme in modo che la cassetta test riempia lo schermo (come mostrato di seguito).';
lang.OTHERTESTS_PAGE_TITLE = 'Altri test';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Hai inviato sangue per un test di anticorpi in laboratorio?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Hai eseguito un campione di espettorato / nasale / gola con PCR?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Hai fatto un test sull\'antigene virale?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Hai fatto un esame radiografico / TC?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Hai avuto una diagnosi medica?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'Non ho fatto';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Risultato negativo';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Risultato positivo';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Risultato sconosciuto';
lang.EMAILCONSENT_PAGE_TITLE = 'Ultima domanda';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Saresti interessato a conoscere studi, programmi di test o una prova per aiutarti a confermare se ti sei completamente ripreso, per donare plasma o per sapere se sei asintomatico o immune al Coronavirus?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Interessato, mandami maggiori informazioni quando lo scopri';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Interessato, ma non inviarmi informazioni per favore';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'No, non sono interessato';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Si prega di lasciare il proprio indirizzo posta elettronica di seguito';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

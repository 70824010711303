import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Iya';
lang.GLOBAL_LABEL_NO = 'Tidak';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'Saya tidak tahu / tidak ingat';
lang.GLOBAL_LABEL_NEXT = 'Lanjut';
lang.GLOBAL_LABEL_BACK = 'Kembali';
lang.GLOBAL_LABEL_NONE = 'Tidak ada';
lang.GLOBAL_LABEL_SUBMIT = 'Kirimkan';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Yg dibutuhkan';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'silakan isi alamat email';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Hai, Selamat datang di Codi';
lang.MAINPAGE_PAGE_TEXT = 'Silakan pilih dari opsi di bawah ini:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Jawab Kuisioner';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Tonton Instruksi Video';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Unggah Gambar Uji';
lang.GENERALINFORMATION_PAGE_TITLE = 'Informasi Umum';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Apa jenis kelamin yang Anda tugaskan saat lahir?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Pria';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Perempuan';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Lainnya / Memilih untuk tidak mengatakan';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Apa kelompok umurmu?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Saya lebih suka tidak mengatakannya';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Bagaimana kesehatanmu?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Saya sangat bugar dan jarang sakit';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Saya lebih bugar dan lebih sehat daripada rata-rata';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Saya kurang bugar dan sehat dari rata-rata';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'Saya sama sekali tidak sehat';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Manakah dari berikut ini yang Anda miliki / ajukan kepada Anda?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Tak satu pun dari yang berikut ini';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Kondisi jantung ringan';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Kondisi jantung kronis';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Masalah paru-paru ringan seperti asma ringan';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Masalah paru-paru kronis';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Diabetes ringan atau batas';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Diabetes Kronis';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Baru-baru ini (5 tahun terakhir) Perawatan kanker dengan kemo';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Pengobatan kanker yang lalu (lebih dari 5 tahun yang lalu) dengan kemo';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Fungsi ginjal berkurang';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Penyakit hati kronis';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Saya minum obat imunosupresan';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'saya hamil';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Apakah Anda mendapatkan suntikan flu dalam setahun terakhir?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Lokasi dan Perjalanan';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Di mana kamu tinggal sekarang?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Masukkan hanya tiga huruf atau angka pertama dari kode pos / kode pos Anda.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Sudahkah Anda bepergian ke negara lain sejak 1 Januari 2020?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Ya, sebelum mereka "terkunci"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Ya, ketika mereka berada di "kuncian"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Ya, setelah mereka keluar dari "kuncian"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Silakan masukkan tepat 3 karakter';
lang.TAKEPHOTO_PAGE_HEADER = 'Ambil Halaman Foto';
lang.TAKEPHOTO_PAGE_BTN = 'Memotret';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Perjalanan Berlanjut';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Negara mana yang telah Anda kunjungi sejak 1 Januari 2020? (Jika Anda pergi ke beberapa negara, silakan pilih negara di mana Anda pikir Anda bisa menangkap COVID-19 atau memiliki risiko tertinggi untuk menangkapnya)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'Tanggal berapa Anda memasuki negara itu?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'Tanggal berapa Anda meninggalkan negara itu?';
lang.EXPOSURERISK_PAGE_TITLE = 'Risiko pajanan';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Apakah Anda pergi bekerja selama kuncian negara Anda?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Ya, tetapi saya berinteraksi dengan beberapa orang per hari';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Ya, dan saya berinteraksi dengan banyak orang per hari';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Ya, dan saya berinteraksi dengan banyak orang / pasien per hari di pusat layanan kesehatan.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'Apa yang Anda hindari menangkap COVID-19 di luar rumah Anda?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Saya selalu menjaga jarak 6 kaki / 2 m dari orang-orang.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Saya selalu memakai topeng / penutup wajah.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Saya tidak pernah menghabiskan lebih dari 30 menit di dalam ruangan / di dalam kendaraan bersama orang lain.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Saya sering mencuci tangan dan jarang menyentuh wajah saya.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Di luar rumah Anda, apakah Anda menghabiskan waktu dengan siapa saja yang memiliki atau segera setelah dikembangkan ...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Dikonfirmasi COVID-19 (Diagnosis positif)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Kemungkinan gejala COVID-19';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Bukan dari salah satu di atas';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Berapa banyak orang yang tinggal bersama Anda? (Silakan ketik nomor, mis. 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Apakah Anda pikir ada orang di rumah Anda yang mungkin menderita COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Ya, mungkin';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Ya, pasti (hasil tes positif)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Teman serumah sakit';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Berapa banyak orang yang hidup dengan Anda yang memiliki gejala COVID-19? Silakan ketik nomor, mis. 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Kapan orang pertama di rumah Anda sakit?';
lang.WEREYOUILL_PAGE_TITLE = 'Apakah kamu pernah sakit?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Sudahkah Anda merasa sakit sejak Januari 2020? (Jika Anda mengatakan tidak, Anda tidak akan ditanya tentang gejala)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'Tidak, aku baik-baik saja. Saya belum merasakan sakit sama sekali sejak Januari 2020';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Saya mulai merasa sakit';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Saya sekarang merasa cukup sakit';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Saya merasa sangat sakit';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Saya masih sakit tetapi merasa lebih baik';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Saya pulih, tetapi sekarang merasa lebih buruk lagi';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Ya, tapi saya merasa sepenuhnya pulih';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Gejala Anda';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Manakah dari gejala berikut yang Anda miliki?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Tidak ada gejala';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Sangat lelah';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Sakit kepala';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Sendi dan / atau otot pegal';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Sakit tenggorokan';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Hidung tersumbat';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Pilek';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Batuk kering';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Batuk disertai dahak';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Bersin';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Demam';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Panas dingin';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Kehilangan indra perasa / penciuman';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Napas pendek';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Gangguan perut / diare';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Muntah / mual';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Ruam kulit';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Seberapa keras Anda, atau Anda merasa sulit bernapas? (2 = sesak nafas ringan, 8 = sesak nafas berat)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Seberapa buruk Anda, atau apakah Anda merasa secara keseluruhan? (2 = hanya sedikit sakit, 8 = sakit parah / pergi ke rumah sakit)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'Seberapa cemasnya Anda tentang COVID19? (2 = sedikit cemas, 8 = sangat cemas)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'Kapan Anda pertama kali mengalami gejala?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Sudah berapa lama Anda merasa sakit / memiliki gejala?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Beberapa hari atau kurang';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Kurang dari satu minggu';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Satu hingga dua minggu';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Dua hingga tiga minggu';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Lebih dari tiga minggu';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Saya masih sakit!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Apakah / Apakah Anda pikir Anda terinfeksi Coronavirus / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'Tidak mungkin';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Peluang kecil (~ 25% yakin)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Kesempatan yang masuk akal (~ 50% yakin)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Cukup yakin (~ 75% yakin)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Benar-benar yakin (~ 90% + pasti)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Ya, 100%: Saya dites positif';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Ya, 100%: Saya dirawat di rumah sakit';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Ya, 100%: Saya dirawat di ruang perawatan intensif';
lang.COVIDTESTING_PAGE_TITLE = 'Pengujian COVID';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Sudahkah Anda melakukan tes antibodi untuk memeriksa apakah Anda pernah / pernah menggunakan COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'Tidak, saya belum melakukan tes antibodi';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'Tidak, tapi saya melakukan tes yang berbeda';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Ya, dan saya dinyatakan positif';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Ya, tapi saya diuji negatif';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Ya, tapi saya tidak tahu hasilnya';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'Tanggal berapa kamu melakukan tes?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'Tes antibodi apa yang Anda lakukan untuk mengetahui apakah Anda pernah / pernah menggunakan COVID-19?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Tes darah Antibodi cepat (Immunoassay aliran lateral)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Dikirim darah untuk tes antibodi laboratorium';
lang.ANTIBODYTEST_PAGE_TITLE = 'Tes antibodi';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Seperti apa hasil tes Anda?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'C saja';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C dan IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C dan IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM dan IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Hanya IgG (tanpa C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Hanya IgM (tanpa C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Hanya IgG dan IgM (tanpa C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Tidak ada, tidak ada garis';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Bukan dari salah satu di atas';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Unggah foto uji antibodi yang diambil langsung dari atas, dengan pencahayaan yang rata sehingga kaset uji memenuhi layar (seperti yang di bawah).';
lang.OTHERTESTS_PAGE_TITLE = 'Tes lainnya';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Apakah Anda mengirim darah untuk tes antibodi laboratorium?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Sudahkah Anda melakukan swab sputum / hidung / tenggorokan untuk PCR?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Sudahkah Anda melakukan tes Antigen virus?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Apakah Anda sudah menjalani pemeriksaan X-ray / CT?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Sudahkah Anda mendiagnosis dokter?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'Tidak melakukan';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Hasil negatif';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Hasil yang positif';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Hasil tidak dikenal';
lang.EMAILCONSENT_PAGE_TITLE = 'Pertanyaan terakhir';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Apakah Anda tertarik mengetahui tentang studi, program pengujian, atau uji coba, untuk membantu mengonfirmasi apakah Anda telah sepenuhnya pulih, menyumbangkan plasma, atau apakah Anda kebal terhadap Virus Corona?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Tertarik, kirimi saya informasi lebih lanjut ketika Anda mendengarnya';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Tertarik, tetapi tidak ada informasi';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'Tidak, tidak tertarik';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Silakan tinggalkan alamat email Anda di bawah ini';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Ja';
lang.GLOBAL_LABEL_NO = 'Nein';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'Ich weiß es nicht / kann mich nicht erinnern';
lang.GLOBAL_LABEL_NEXT = 'Nächster';
lang.GLOBAL_LABEL_BACK = 'Zurück';
lang.GLOBAL_LABEL_NONE = 'Keiner';
lang.GLOBAL_LABEL_SUBMIT = 'Einreichen';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Erforderlich';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Hallo, Willkommen bei Codi';
lang.MAINPAGE_PAGE_TEXT = 'Bitte wählen Sie aus den folgenden Optionen:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Fragebogen beantworten';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Videoanweisungen ansehen';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Testbild hochladen';
lang.GENERALINFORMATION_PAGE_TITLE = 'Allgemeine Information';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Welches Geschlecht wurde Ihnen bei der Geburt zugewiesen?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Mann';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Frau';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Andere / Keine Angabe';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Was ist deine Altersgruppe?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Keine Angabe';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Wie geht es deiner Gesundheit?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Ich bin sehr fit und selten krank';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Ich bin fitter und gesünder als der Durchschnitt';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Ich bin weniger fit und gesund als der Durchschnitt';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'Ich bin überhaupt nicht sehr gesund';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Welche der folgenden Möglichkeiten haben / bewerben Sie sich?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Keine der folgenden';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Leichte Herzerkrankung';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Chronische Herzerkrankung';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Leichtes Lungenproblem wie leichtes Asthma';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Chronische Lungenprobleme';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Milder oder Borderline-Diabetes';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Chronischer Diabetes';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Letzte (letzte 5 Jahre) Krebsbehandlung mit Chemotherapie';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Frühere Krebsbehandlung (vor über 5 Jahren) mit Chemotherapie';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Reduzierte Nierenfunktion';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Chronische Lebererkrankung';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Ich nehme Immunsuppressiva';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'Ich bin schwanger';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Haben Sie im letzten Jahr eine Grippeimpfung bekommen?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Lage und Reise';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Wo lebst du gerade?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Bitte geben Sie nur die ersten drei Buchstaben oder Zahlen Ihrer Postleitzahl ein.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Sind Sie seit dem 1. Januar 2020 in ein anderes Land gereist?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Ja, bevor sie in "Lockdown" waren';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Ja, während sie in "Lockdown" waren';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Ja, nachdem sie aus "Lockdown" herausgekommen sind';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Bitte geben Sie genau 3 Zeichen ein';
lang.TAKEPHOTO_PAGE_HEADER = 'Fotoseite aufnehmen';
lang.TAKEPHOTO_PAGE_BTN = 'Foto machen';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Reise fortgesetzt';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Welche Länder haben Sie seit dem 1. Januar 2020 besucht? (Wenn Sie in mehrere Länder gereist sind, wählen Sie das Land aus, von dem Sie glauben, dass es COVID-19 erfasst haben könnte oder bei dem Sie das höchste Risiko hatten, es zu bekommen.)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'Wann sind Sie in dieses Land eingereist?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'Wann haben Sie dieses Land verlassen?';
lang.EXPOSURERISK_PAGE_TITLE = 'Expositionsrisiko';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Sind Sie während der Sperrung Ihres Landes zur Arbeit gegangen?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Ja, aber ich habe mit wenigen Menschen pro Tag interagiert';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Ja, und ich habe täglich mit vielen Menschen interagiert';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Ja, und ich habe täglich mit vielen Menschen / Patienten im Gesundheitswesen interagiert.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'Was haben Sie getan, um COVID-19 nicht außerhalb Ihres Hauses zu fangen?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Ich habe immer 2 m Abstand zu Menschen gehalten.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Ich trug immer eine Maske / Gesichtsbedeckung.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Ich habe nie mehr als 30 Minuten drinnen / in Fahrzeugen mit anderen verbracht.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Ich wusch häufig meine Hände und berührte selten mein Gesicht.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Haben Sie außerhalb Ihres Hauses Zeit mit jemandem verbracht, der sich entwickelt hat oder kurz danach ...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Bestätigtes COVID-19 (positive Diagnose)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Mögliche COVID-19-Symptome';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Nichts des oben Genannten';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Mit wie vielen Menschen lebst du zusammen? (Bitte geben Sie eine Zahl ein, z. B. 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Denken Sie, dass jemand in Ihrem Haus COVID-19 gehabt haben könnte?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Ja, vielleicht';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Genau (positives Testergebnis)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Kranke Mitbewohner';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Wie viele Menschen, mit denen Sie zusammenleben, hatten möglicherweise COVID-19-Symptome? Bitte geben Sie eine Nummer ein, z. 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Wann wurde die erste Person in Ihrem Haus krank?';
lang.WEREYOUILL_PAGE_TITLE = 'Warst du jemals krank?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Haben Sie sich seit Januar 2020 überhaupt krank gefühlt? (Wenn Sie Nein sagen, werden Sie nicht nach Symptomen gefragt.)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'Nein mir geht es gut. Ich habe mich seit Januar 2020 überhaupt nicht krank gefühlt';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Ich fange an, mich krank zu fühlen';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Ich fühle mich jetzt ziemlich krank';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Ich fühle mich sehr krank';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Ich bin immer noch krank, aber es geht mir besser';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Ich erholte mich, fühlte mich aber jetzt wieder schlechter';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Ja, aber ich fühle mich vollständig erholt';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Ihre Symptome';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Welches der folgenden Symptome hatten / hatten Sie?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Keine Symptome';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Sehr müde';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Kopfschmerzen';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Achy Gelenke und / oder Muskeln';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Halsschmerzen';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Verstopfte Nase';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Schleim durch die Nase';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Ein trockener Husten';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Ein Husten mit Auswurf';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Niesen';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Fieber';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Schüttelfrost';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Verlust des Geschmacks- / Geruchssinns';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Kurzatmig';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Magenstörung / Durchfall';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Erbrechen / Übelkeit';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Hautausschlag';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Wie schwer warst du oder fällt es dir zu atmen? (2 = leichte Atemnot, 8 = schwere Atemnot)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Wie schlecht warst du oder fühlst du dich insgesamt? (2 = nur leicht krank, 8 = schwer krank / ins Krankenhaus gegangen)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'Wie besorgt waren / sind Sie über COVID19? (2 = etwas ängstlich, 8 = sehr ängstlich)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'Wann traten zum ersten Mal Symptome auf?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Wie lange haben Sie sich krank gefühlt / hatten Symptome?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Ein paar Tage oder weniger';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Weniger als eine Woche';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Ein bis zwei Wochen';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Zwei bis drei Wochen';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Mehr als drei Wochen';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Ich bin immer noch krank!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Wisen / glauben Sie, mit Coronavirus / COVID-19 infiziert zu sein?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'Auf keinen Fall';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Eine kleine Chance (~ 25% sicher)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Eine vernünftige Chance (~ 50% sicher)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Ziemlich sicher (~ 75% sicher)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absolut überzeugt (~ 90% + sicher)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Ja, 100%: Ich habe positiv getestet';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Ja, 100%: Ich wurde im Krankenhaus behandelt';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Ja, 100%: Ich wurde auf der Intensivstation behandelt';
lang.COVIDTESTING_PAGE_TITLE = 'COVID-Test';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Haben Sie einen Antikörpertest durchgeführt, um zu überprüfen, ob Sie COVID-19 haben / hatten?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'Nein, ich habe keinen Antikörpertest durchgeführt';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'Nein, aber ich habe einen anderen Test gemacht';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Ja, und ich habe positiv getestet';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Ja, aber ich habe negativ getestet';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Ja, aber ich kenne das Ergebnis nicht';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'Wann hast du den Test gemacht?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'Welche Art von Antikörpertest haben Sie durchgeführt, um festzustellen, ob Sie COVID-19 haben / hatten?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Schneller Antikörper-Bluttest (Lateral Flow Immunoassay)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Gesendetes Blut für den Laborantikörpertest';
lang.ANTIBODYTEST_PAGE_TITLE = 'Antikörpertest';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Wie sah Ihr Testergebnis aus?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'Nur C';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C und IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C und IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM und IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Nur IgG (kein C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Nur IgM (kein C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Nur IgG und IgM (kein C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Keine, keine Zeilen';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Nichts des oben Genannten';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Bitte laden Sie ein Foto des Antikörpertests hoch, das direkt von oben aufgenommen wurde, mit gleichmäßiger Beleuchtung, damit die Testkassette den Bildschirm ausfüllt (wie unten).';
lang.OTHERTESTS_PAGE_TITLE = 'Andere Tests';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Haben Sie Blut für einen Laborantikörpertest geschickt?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Haben Sie einen Sputum / Nasal / Throat-Tupfer für die PCR gemacht?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Haben Sie einen viralen Antigen-Test durchgeführt?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Haben Sie Röntgen- / CT-Tests durchgeführt?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Haben Sie eine Arztdiagnose gehabt?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'Tat es nicht';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Negatives Ergebnis';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Positives Ergebnis';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Unbekanntes Ergebnis';
lang.EMAILCONSENT_PAGE_TITLE = 'Letzte Frage';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Möchten Sie mehr über Studien, Testprogramme oder Studien erfahren, um zu bestätigen, ob Sie sich vollständig erholt haben, um Plasma zu spenden oder ob Sie möglicherweise gegen das Corona-Virus immun sind?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Interessiert, senden Sie mir weitere Informationen, wenn Sie davon erfahren';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Interessiert, aber bitte keine Informationen';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'Nein, nicht interessiert';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Bitte hinterlassen Sie unten Ihre E-Mail-Adresse';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

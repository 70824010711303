import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Si';
lang.GLOBAL_LABEL_NO = 'No';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'No se / no recuerdo';
lang.GLOBAL_LABEL_NEXT = 'Próximo';
lang.GLOBAL_LABEL_BACK = 'Atras';
lang.GLOBAL_LABEL_NONE = 'Ninguno';
lang.GLOBAL_LABEL_SUBMIT = 'Enviar';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Requerido';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Porfavor, ingrese una dirección de correo Valida';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Hola, Bienvenidos a Codi';
lang.MAINPAGE_PAGE_TEXT = 'Seleccione entre las siguientes opciones:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Responder Cuestionario';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Ver instrucciones en video';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Subir imagen de prueba';
lang.GENERALINFORMATION_PAGE_TITLE = 'Información general';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = '¿Qué sexo le asignaron al nacer?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Masculino';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Femenino';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Otro / Prefiero no decirlo';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = '¿Cuál es tu grupo de edad?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Prefiero no decirlo';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = '¿Cómo está tu salud?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Estoy muy en forma y rara vez me enfermo';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Estoy más en forma y más saludable que el promedio';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Estoy menos en forma y saludable que el promedio';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'No soy muy saludable';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = '¿Cuál de los siguientes tiene / aplica para usted?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Ninguno de los siguientes';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Condición cardíaca leve';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Enfermedad cardíaca crónica';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Problema pulmonar leve como asma leve';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Problemas pulmonares crónicos';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Diabetes leve o limítrofe';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Diabetes Crónica';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Tratamiento reciente (últimos 5 años) del cáncer con quimioterapia';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Tratamiento contra el cáncer en el pasado (hace más de 5 años) con quimioterapia';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Función renal reducida';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Enfermedad cronica del higado';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Tomo medicamentos inmunosupresores';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'Estoy embarazada';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = '¿Recibiste una vacuna contra la influenza el año pasado?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Ubicación y viaje';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = '¿Dónde vives ahora?';
lang.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT = 'Please enter which state you live in.';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Ingrese solo las primeras tres letras o números de su código postal.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = '¿Has viajado a otros países desde el 1 de enero de 2020?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Sí, antes de que estuvieran en "cierre de emergencia"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Sí, mientras estaban en "cierre de emergencia"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Sí, después de que salieron del "cierre de emergencia"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Por favor, introduzca exactamente 3 caracteres';
lang.TAKEPHOTO_PAGE_HEADER = 'Página de Toma de Foto';
lang.TAKEPHOTO_PAGE_BTN = 'Tomar foto';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Viaje continuo';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = '¿Qué países has visitado desde el 1 de enero de 2020? (Si fuiste a varios países, elige el que creas que pudiste haber capturado COVID-19 o que tuviste el mayor riesgo de contraerlo)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = '¿En qué fecha entraste a ese país?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = '¿En qué fecha te fuiste de ese país?';
lang.EXPOSURERISK_PAGE_TITLE = 'Riesgo de exposición';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = '¿Fuiste a trabajar durante el encierro de tu país?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Sí, pero interactué con pocas personas por día.';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Sí, e interactué con muchas personas por día.';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Sí, e interactué con muchas personas / pacientes por día en un entorno de atención médica.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = '¿Qué hizo para evitar contagiarse de COVID-19 fuera de su hogar?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Siempre mantuve una distancia de 2 m de la gente.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Siempre usé una máscara / cubierta facial.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Nunca pasé más de 30 minutos en interiores / en vehículos con otras personas.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Frecuentemente me lavo las manos y rara vez me toco la cara.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Fuera de su casa, ¿pasó tiempo con alguien que se había desarrollado o poco después ...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'COVID-19 confirmado (diagnóstico positivo)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Posibles síntomas de COVID-19';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Ninguno de las anteriores';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = '¿Con cuántas personas vives? (Escriba un número, p.ej. 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = '¿Crees que alguien en tu casa podría haber tenido COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Sí, posiblemente';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Sí, definitivamente (resultado positivo de la prueba)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Compañeros de vivienda enfermos';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = '¿Cuántas personas con las que vive han tenido posibles síntomas de COVID-19? Por favor escriba un número, p.ej. 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = '¿Cuándo se enfermó la primera persona en su casa?';
lang.WEREYOUILL_PAGE_TITLE = '¿Alguna vez estuviste enfermo?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = '¿Te has sentido mal desde enero de 2020? (Si dice que no, no se le preguntará acerca de los síntomas)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'No, estoy bien. No me he sentido mal desde enero de 2020';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Estoy empezando a sentirme enfermo';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Ahora me siento bastante enfermo';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Me siento muy enfermo';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Todavía estoy enfermo pero me siento mejor';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Me estaba recuperando, pero ahora me siento enfermo otra vez';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Sí, pero me siento completamente recuperado.';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Sus síntomas';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = '¿Cuál de los siguientes síntomas tenía / tiene?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Sin síntomas';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Realmente cansado';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Un dolor de cabeza';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Dolor en las articulaciones y / o músculos';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Dolor de garganta';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Nariz congestionada';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Mocos en la Nariz';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Tos seca';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Tos con esputo';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Estornudos';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Fiebre';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Escalofrios';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Pérdida del sentido del gusto / olfato.';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Dificultad al respirar';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Malestar estomacal / diarrea';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Vómitos / náuseas';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Ronchas o Alergias en la Piel';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = '¿Qué tanto te costó o te resulta respirar? (2 = dificultad para respirar leve, 8 = dificultad para respirar severa)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = '¿Qué tan mal te sentiste o te sentiste en general? (2 = solo levemente enfermo, 8 = gravemente enfermo / fue al hospital)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = '¿Qué tan ansioso estaba / estás por COVID19? (2 = un poco ansioso, 8 = muy ansioso)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = '¿Cuándo experimentaste los primeros síntomas?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = '¿Durante cuánto tiempo se sintió enfermo / tuvo síntomas?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Unos días o menos';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Menos de una semana';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Una o dos semanas';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Dos o tres semanas';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Más de tres semanas';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Todavía estoy enfermo!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = '¿Creía que estaba infectado con Coronavirus / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'De ninguna manera';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Una pequeña posibilidad (~ 25% seguro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Una oportunidad razonable (~ 50% seguro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Bastante seguro (~ 75% seguro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absolutamente convencido (~ 90% + seguro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Sí, 100%: he dado positivo';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Sí, 100%: me trataron en el hospital';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Sí, 100%: me trataron en cuidados intensivos';
lang.COVIDTESTING_PAGE_TITLE = 'Prueba del COVID';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = '¿Se ha hecho una prueba de anticuerpos para verificar si tiene / tuvo COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'No, no he hecho una prueba de anticuerpos.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'No, pero hice un tipo diferente de prueba';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Sí, y salí positivo';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Sí, pero obtuve un resultado negativo.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Sí, pero no sé el resultado.';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = '¿En qué fecha hiciste la prueba?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = '¿Qué tipo de prueba de anticuerpos realizó para ver si tenía / tuvo COVID-19?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Análisis rápido de sangre de anticuerpos (inmunoensayo de flujo lateral)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Envío de sangre para análisis de anticuerpos de laboratorio.';
lang.ANTIBODYTEST_PAGE_TITLE = 'Prueba de anticuerpos';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = '¿Cómo se veía el resultado de su prueba?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'Solo C';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C e IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C e IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM e IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Solo IgG (sin C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Solo IgM (sin C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'IgG e IgM solamente (sin C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Ninguno, sin líneas';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Ninguno de las anteriores';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Cargue una foto de la prueba de anticuerpos tomada directamente desde arriba, con una iluminación uniforme para que el casete de prueba llene la pantalla (como la siguiente).';
lang.OTHERTESTS_PAGE_TITLE = 'Otras pruebas';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = '¿Envió sangre para una prueba de laboratorio de anticuerpos?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = '¿Ha realizado una muestra de esputo / nasal / garganta para PCR?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = '¿Has hecho una prueba de antígeno viral?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = '¿Le han hecho una prueba de rayos X / CT?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = '¿Has tenido un diagnóstico médico?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'No hizo';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Resultado negativo';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Resultado positivo';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Resultado desconocido';
lang.EMAILCONSENT_PAGE_TITLE = 'Ultima Pregunta';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = '¿Le interesaría saber acerca de algún estudio, programa de prueba o ensayo para ayudar a confirmar si se ha recuperado por completo, para donar plasma o si es inmune al Coronavirus?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Interesado, envíeme más información cuando se entere';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Interesado, pero no me manden información por favor';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'No, no estoy interesado';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Por favor deje su dirección de correo electrónico a continuación';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

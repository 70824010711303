import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Sí';
lang.GLOBAL_LABEL_NO = 'No';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'No sé / no recordo';
lang.GLOBAL_LABEL_NEXT = 'Pròxim';
lang.GLOBAL_LABEL_BACK = 'esquena';
lang.GLOBAL_LABEL_NONE = 'Cap';
lang.GLOBAL_LABEL_SUBMIT = 'Presentar';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Obligatori';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'siusplau, introdueixi una adreça de correu electrònic vàlida';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Hola, Benvingut a Codi';
lang.MAINPAGE_PAGE_TEXT = 'Seleccioneu una de les opcions següents:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Respondre el Qüestionari';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Consulteu les instruccions en vídeo';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Carregueu imatge de prova';
lang.GENERALINFORMATION_PAGE_TITLE = 'Informació general';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Quin sexe li van assignar en néixer?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Mascle';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Femení';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Un altre / Preferiu no dir-ho';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Quina és la vostra franja d’edat?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Prefereixo no dir-ho';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Com estàs de salut?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Estic molt en forma i poques vegades estic malalt';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Sóc més ajustat i saludable de la mitjana';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Estic menys en forma i saludable que la mitjana';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'No sóc gaire sana';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Quin dels següents us ha aplicat?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Cap de les següents';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Afecció cardíaca suau';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Afecció cardíaca crònica';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Problema pulmonar suau com asma lleu';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Problemes pulmonars crònics';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Diabetis lleu o límit';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Diabetis crònica';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Últims (darrers 5 anys) Tractament contra el càncer amb quimio';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Tractament del càncer passat (fa més de 5 anys) amb quimio';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Funció renal reduïda';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Malalties hepàtiques cròniques';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Prenc drogues immunosupressores';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'estic embarassada';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Vau rebre una vacuna contra la grip el darrer any?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Ubicació i viatges';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'On vius ara mateix?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Introduïu només les tres primeres lletres o números del vostre codi postal / codi postal.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Heu viatjat a qualsevol altre país des de l’1 de gener del 2020?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Sí, abans eren "bloquejats"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Sí, mentre estaven en "bloqueig"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Sí, després que van sortir de "bloqueig"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Introduïu exactament 3 caràcters';
lang.TAKEPHOTO_PAGE_HEADER = 'Realitza una pàgina fotogràfica';
lang.TAKEPHOTO_PAGE_BTN = 'Fer una foto';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Viatge continuat';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Quins països heu visitat des de l\'1 de gener de 2020? (Si vau anar a diversos països, seleccioneu aquell on creieu que podríeu haver agafat COVID-19 o teniu el risc més alt d’atrapar-lo)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'Quina data heu entrat a aquell país?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'Quina data vau deixar aquest país?';
lang.EXPOSURERISK_PAGE_TITLE = 'Risc d’exposició';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Heu anat a la feina durant el tancament del vostre país?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Sí, però interactuava amb poca gent al dia';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Sí, i interactuava amb molta gent al dia';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Sí, i vaig interactuar amb moltes persones / pacients al dia en un entorn sanitari.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'Què heu fet per evitar atrapar COVID-19 fora de casa?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Sempre vaig mantenir una distància de 2 m 2 de la gent.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Sempre portava una màscara / una tapadora de cara.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Mai vaig passar més de 30 minuts en interior / en vehicles amb altres persones.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Sovint em rentava les mans i rarament em tocava la cara.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Fora de casa vostra, vau passar temps amb algú que tingués desenvolupat o al cap de poc ...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'COVID-19 confirmat (diagnòstic positiu)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Possibles símptomes del COVID-19';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Cap de les anteriors';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Amb quantes persones conviu? (Introduïu un número, per exemple, 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Creus que algú de casa teva podria haver tingut COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Sí, possiblement';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Sí, definitivament (resultat de la prova positiva)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Habitatges malalts';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Quantes persones que viuen han tingut possibles símptomes de COVID-19? Introduïu un número, per exemple. 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Quan es va emmalaltir la primera persona de casa?';
lang.WEREYOUILL_PAGE_TITLE = 'Heu estat mai malalt?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Us heu sentit malament des del gener del 2020? (Si dius que no, no us preguntaran cap símptoma)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'No, estic bé. No m’he sentit malament des del gener del 2020';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Estic començant a sentir-me malament';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Ara em sento bastant malalt';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Em sento molt malalt';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Encara estic malalt, però em sento millor';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Em recuperava, però ara tornava a sentir pitjor';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Sí, però em sento totalment recuperat';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Els seus símptomes';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Quins dels símptomes següents teniu / teniu?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'No hi ha símptomes';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Molt cansat';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Un mal de cap';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Articulacions i / o músculs dolents';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Mal de coll';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Nas congestionat';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Nariz raig';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Una tos seca';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Una tos amb esput';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Esternuts';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Febre';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Calfreds';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Pèrdua del gust / olor';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Falta d\'alè';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Trastorn de l\'estómac / diarrea';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Vòmits / nàusees';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Erupció cutània';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Què et va costar, o et sembla respirar? (2 = respiració lleu, 8 = escassa respiració)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Què tan malament, o se sent en general? (2 = només una mica malalt, 8 = greument malalt / van anar a l’hospital)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'Quina inquietud teníeu / esteu amb COVID19? (2 = una mica ansiós, 8 = molt ansiós)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'Quan vas experimentar símptomes per primera vegada?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Per quant temps us sentíeu malalt / teniu símptomes?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Uns dies o menys';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Menys d’una setmana';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Una a dues setmanes';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'De dues a tres setmanes';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Més de tres setmanes';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Encara estic malalt!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Creieu / creieu que estava infectat amb Coronavirus / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'De cap manera';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Una petita possibilitat (~ 25% segur)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Una oportunitat raonable (~ 50% segur)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Bastant segur (~ 75% segur)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absolutament convençut (~ 90% + segur)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Sí, 100%: vaig fer positiu';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Sí, 100%: em van atendre a l\'hospital';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Sí, 100%: vaig ser atès en cures intensives';
lang.COVIDTESTING_PAGE_TITLE = 'Prova de COVID';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Heu fet una prova d’anticossos per comprovar si heu tingut / teníeu COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'No, no he fet una prova d’anticossos';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'No, però vaig fer un tipus de prova diferent';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Sí, i vaig resultar positiu';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Sí, però vaig provar negatiu';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Sí, però no en sé el resultat';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'Quina data heu fet la prova?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'Quin tipus de prova d’anticossos heu fet per veure si heu tingut / teníeu COVID-19?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Prova de sang ràpida en anticorps (test immunològic de flux lateral)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Sang enviada per fer una prova d’anticossos de laboratori';
lang.ANTIBODYTEST_PAGE_TITLE = 'Prova d\'anticossos';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Com va ser el resultat del vostre test?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'C només';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C i IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C i IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM i IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Només IgG (sense C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Només IgM (sense C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Només IgG i IgM (sense C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Cap, cap línia';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Cap de les anteriors';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Carregueu una foto de la prova d\'anticossos presa directament a la part superior, amb una il·luminació uniforme perquè el casset de prova ompli la pantalla (com la següent).';
lang.OTHERTESTS_PAGE_TITLE = 'Altres proves';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Has enviat sang per fer una prova d’anticossos de laboratori?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Heu fet un cotonet Sputum / Nasal / Throat per PCR?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Heu fet una prova d’antigen viral?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Heu fet proves de rajos X / TC?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Heu fet un diagnòstic de metge?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'No ho vaig fer';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Resultat negatiu';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Resultat positiu';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Resultat desconegut';
lang.EMAILCONSENT_PAGE_TITLE = 'Pregunta final';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'T’interessaria conèixer alguns estudis, programes de prova o assaigs, per ajudar a confirmar si t’has recuperat completament, per donar plasma o si pots estar immune al virus de la Corona?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Interessat, envieu-me més informació quan en tingueu coneixement';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Interessat, però no hi ha informació';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'No, no m\'interessa';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Deixeu la vostra adreça de correu electrònic a continuació';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'はい';
lang.GLOBAL_LABEL_NO = '番号';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'わからない/思い出せない';
lang.GLOBAL_LABEL_NEXT = '次';
lang.GLOBAL_LABEL_BACK = 'バック';
lang.GLOBAL_LABEL_NONE = 'なし';
lang.GLOBAL_LABEL_SUBMIT = '参加する';
lang.GLOBAL_FORMVALIDATION_REQUIRED = '必須';
lang.GLOBAL_FORMVALIDATION_EMAIL = '有効なメールアドレスを入力してください';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'こんにちは、コーディへようこそ';
lang.MAINPAGE_PAGE_TEXT = '以下のオプションから選択してください：';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'アンケートに答える';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'ビデオの説明を見る';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'テスト画像をアップロード';
lang.GENERALINFORMATION_PAGE_TITLE = '一般情報';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = '出生時に割り当てられた性別は？';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = '男性';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = '女性';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'その他/言わないでおく';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'あなたの年齢層は何ですか？';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = '言いたくない';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = '体の調子はどう？';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = '私はとても体調がよく、めったに病気ではありません';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = '私は平均より健康で元気です';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = '平均より健康と体調が悪い';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = '私は全然健康ではありません';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = '次のうち、あなたに当てはまるものはどれですか？';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = '以下のどれでもない';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = '穏やかな心臓の状態';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = '慢性心疾患';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = '軽度の喘息のような軽度の肺の問題';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = '慢性肺の問題';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = '軽度または境界の糖尿病';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = '慢性糖尿病';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = '最近（過去5年間）の化学療法によるがん治療';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = '過去のがん治療（5年以上前）と化学療法';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = '腎機能の低下';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = '慢性肝疾患';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = '免疫抑制剤を服用しています';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = '妊娠しています';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = '昨年、インフルエンザの予防接種を受けましたか？';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'ロケーションと旅行';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = '今どこに住んでいますか？';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = '郵便番号/郵便番号の最初の3文字または数字のみを入力してください。';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = '2020年1月1日以降、他の国に旅行しましたか？';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'はい、彼らが「封鎖」される前に';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'はい、彼らは「ロックダウン」状態でした';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'はい、彼らが「封鎖」から抜け出した後';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = '正確に3文字を入力してください';
lang.TAKEPHOTO_PAGE_HEADER = '写真ページを撮る';
lang.TAKEPHOTO_PAGE_BTN = '写真を撮る';
lang.TRAVELCONTINUED_PAGE_TITLE = '旅行継続';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = '2020年1月1日以降、どの国を訪れましたか？ （複数の国に行った場合は、COVID-19を捕まえた可能性があるか、または捕獲するリスクが最も高いと思われる国を選択してください）';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'その国に入ったのはいつですか？';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'その国を何日出ましたか？';
lang.EXPOSURERISK_PAGE_TITLE = '曝露リスク';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'あなたの国の封鎖中に仕事に行きましたか？';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'はい、しかし私は一日に数人とやり取りしました';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'はい、私は1日あたり多くの人と交流しました';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'はい、私はヘルスケア環境で1日あたり多くの人/患者とやり取りしました。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = '家の外でCOVID-19を捕まえるのを避けるために何をしましたか？';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = '私は常に人から6 ft / 2 mの距離を保ちました。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = '私はいつもマスク/フェイスカバーを着ていました。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = '私は室内/車内で他の人と30分以上過ごしたことはありません。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = '私は頻繁に手を洗い、めったに顔に触れませんでした。';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'あなたの家の外で、開発した人、または開発した直後に誰かと過ごしましたか？';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = '確認されたCOVID-19（陽性診断）';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = '可能なCOVID-19症状';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = '上記のどれでもない';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = '何人住んでいますか？ （数字を入力してください。例：2）';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'あなたの家の誰かがCOVID-19を持っていたかもしれないと思いますか？';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'はい、たぶん';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'はい、間違いなく（肯定的なテスト結果）';
lang.ILLHOUSEMATES_PAGE_TITLE = '病気のハウスメイト';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'あなたと一緒に暮らしている何人がCOVID-19の症状の可能性がありますか？数値を入力してください。 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'あなたの家の最初の人が病気になったのはいつですか？';
lang.WEREYOUILL_PAGE_TITLE = 'あなたは病気になりましたか？';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = '2020年1月以降、気分が悪くなりましたか？ （いいえと答えた場合、症状について尋ねられることはありません）';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'いいえ、大丈夫です。 2020年1月から全然具合が悪い';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = '私は気分が悪くなり始めています';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = '私は今かなり気分が悪い';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = '気分が悪い';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = '私はまだ病気ですが気分が良いです';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = '私は回復していましたが、今は再び悪化しています';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'はい、でも完全に回復しました';
lang.YOURSYMPTOMS_PAGE_TITLE = 'あなたの症状';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = '次の症状のうちどれがありましたか？';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = '症状なし';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = '本当に疲れた';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = '頭痛';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = '痛みを伴う関節や筋肉';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = '喉の痛み';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = '鼻づまり';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = '鼻水';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = '乾いた咳';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = '痰を伴う咳';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'くしゃみ';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = '熱';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = '寒気';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = '味覚・嗅覚の喪失';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = '息を切らして';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = '胃障害/下痢';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = '嘔吐/吐き気';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = '皮膚の発疹';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'どのくらい苦労しましたか、それとも呼吸するのに苦労しましたか？ （2 =軽度の息切れ、8 =重度の息切れ）';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'あなたはどの程度気分が悪かったですか、それとも全体的に感じましたか（2 =わずかに病気、8 =重症/病院に行った）';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'COVID19についてどの程度気になりましたか？ （2 =少し心配、8 =非常に心配）';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = '最初に症状が出たのはいつですか？';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'どのくらいの期間、気分が悪い/症状がありましたか？';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = '数日以内';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = '1週間未満';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = '1〜2週間';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = '2〜3週間';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = '3週間以上';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'まだ病気です！';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'コロナウイルス/ COVID-19に感染したと思いますか？';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'ありえない';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = '小さなチャンス（〜25％確実）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = '妥当なチャンス（〜50％確実）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'かなり確か（75％確実）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = '絶対に確信している（〜90％+確実）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'はい、100％：陽性でした';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'はい、100％：私は病院で治療を受けました';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'はい、100％：私は集中治療を受けました';
lang.COVIDTESTING_PAGE_TITLE = 'COVIDテスト';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'COVID-19の有無を確認するために抗体検査を行いましたか？';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'いいえ、抗体検査は行っていません';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'いいえ、ただし別の種類のテストを行いました';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'はい、私は陽性反応を示しました';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'はい、しかし私は否定的にテストしました';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'はい、でも結果はわかりません';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'いつテストをしましたか。';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'COVID-19の有無を確認するために、どのような抗体検査を行いましたか？';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = '抗体迅速血液検査（ラテラルフローイムノアッセイ）';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = '実験室の抗体検査のために送った血液';
lang.ANTIBODYTEST_PAGE_TITLE = '抗体検査';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'テスト結果はどのようになりましたか？';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'Cのみ';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'CおよびIgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'CおよびIgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C、IgMおよびIgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'IgGのみ（Cなし）';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'IgMのみ（Cなし）';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'IgGおよびIgMのみ（Cなし）';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'なし、線なし';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = '上記のどれでもない';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = '真上から撮影した抗体テストの写真をアップロードしてください。テストカセットが画面全体に表示されるように照明を均一にしてください（下図のように）。';
lang.OTHERTESTS_PAGE_TITLE = 'その他のテスト';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = '実験室での抗体検査のために血液を送りましたか？';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'PCR用の喀痰/鼻腔/咽頭スワブを行いましたか？';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'ウイルス性抗原検査を行いましたか？';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'テストのX線/ CTスキャンを行いましたか？';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'あなたは医者の診断を受けましたか？';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'しませんでした';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = '悪い結果';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = '肯定的な結果';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = '不明な結果';
lang.EMAILCONSENT_PAGE_TITLE = '最後の質問';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = '完全に回復したかどうか、血漿を寄付したかどうか、またはコロナウイルスに免疫があるかどうかを確認するために、研究、試験プログラム、または試験について知りたいですか？';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = '興味があれば、それについて聞いたら詳細を送ってください';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = '興味はありますが、情報はありません';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'いいえ、興味がない';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = '以下にメールアドレスを記入してください';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

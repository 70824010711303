import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Sim';
lang.GLOBAL_LABEL_NO = 'Não';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'Eu não sei, eu não lembro';
lang.GLOBAL_LABEL_NEXT = 'Próximo';
lang.GLOBAL_LABEL_BACK = 'Atrás';
lang.GLOBAL_LABEL_NONE = 'Nenhum';
lang.GLOBAL_LABEL_SUBMIT = 'Enviar';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Requeridos';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Por favor insira um endereço de e-mail válido';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Olá, bem-vindo ao Codi';
lang.MAINPAGE_PAGE_TEXT = 'Selecione entre as seguintes opções:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Responder Questionário';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Veja as instruções em vídeo';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Carregar imagem de teste';
lang.GENERALINFORMATION_PAGE_TITLE = 'Informações gerais';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Que sexo você foi designado no nascimento?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Masculino';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Fêmea';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Outro / prefiro não dizer';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Qual é a sua faixa etária?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Eu prefiro não dizer';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Como está sua saúde?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Estou muito em forma e raramente fico doente';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Sou mais apto e mais saudável que a média';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Estou menos em forma e saudável do que a média';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'Eu não sou muito saudável';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Qual das seguintes opções você tem / se aplica a você?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Nenhum dos seguintes';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Condição cardíaca leve';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Doença cardíaca crônica';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Problema pulmonar leve como asma leve';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Problemas pulmonares crônicos';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Diabetes leve ou limítrofe';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Diabetes Crônico';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Tratamento recente (últimos 5 anos) de câncer com quimioterapia';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Tratamento de câncer no passado (mais de 5 anos atrás) com quimioterapia';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Função renal reduzida';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Doença hepática crônica';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'Eu tomo medicamentos imunossupressores';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'Estou grávida';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Você recebeu uma vacina contra a gripe no ano passado?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Localização e viagens';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Onde vives agora?';
lang.LOCATIONANDTRAVEL_REGIONQUESTION_TEXT = 'Please enter which state you live in.';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Digite apenas as três primeiras letras ou números do seu CEP.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'Você já viajou para outros países desde 1º de janeiro de 2020?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Sim, antes que eles estivessem em "desligamento de emergência"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Sim, enquanto eles estavam em "desligamento de emergência"';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Sim, depois que eles saíram do "desligamento de emergência"';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Digite exatamente 3 caracteres';
lang.TAKEPHOTO_PAGE_HEADER = 'Página de tirar fotos';
lang.TAKEPHOTO_PAGE_BTN = 'Tirar foto';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Viagem contínua';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'Quais países você visitou desde 1º de janeiro de 2020? (Se você foi a vários países, escolha o que você acha que poderia ter capturado o COVID-19 ou que teve o maior risco de contratá-lo)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'Em que data você entrou nesse país?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'Em que data você saiu daquele país?';
lang.EXPOSURERISK_PAGE_TITLE = 'Risco de exposição';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Você foi trabalhar durante o confinamento do seu país?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Sim, mas eu interagi com poucas pessoas por dia.';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Sim, e eu interagi com muitas pessoas por dia.';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Sim, e eu interagi com muitas pessoas / pacientes por dia em um ambiente de saúde.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'O que você fez para evitar colocar o COVID-19 fora de sua casa?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Eu sempre mantive uma distância de 2m das pessoas.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Eu sempre usava uma máscara / capa.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Nunca passei mais de 30 minutos em ambientes fechados / em veículos com outras pessoas.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Lavo frequentemente as mãos e raramente toco no rosto.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Fora de sua casa, você passou algum tempo com alguém que se desenvolveu ou logo depois ...?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Confirmado COVID-19 (diagnóstico positivo)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Possíveis sintomas de COVID-19';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Nenhuma das acima';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Com quantas pessoas você mora? (Escreva um número, por exemplo, 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Você acha que alguém em sua casa poderia ter o COVID-19?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Sim possivelmente';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Sim, definitivamente (resultado positivo do teste)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Colegas de casa doentes';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Quantas pessoas com quem você viveu tiveram possíveis sintomas do COVID-19? Por favor escreva um número, por exemplo 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Quando a primeira pessoa ficou doente em casa?';
lang.WEREYOUILL_PAGE_TITLE = 'Você já esteve doente?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Você se sentiu mal desde janeiro de 2020? (Se você recusar, não será perguntado sobre os sintomas)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'Não estou bem. Não me sinto mal desde janeiro de 2020';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Estou começando a ficar doente';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Agora eu me sinto muito doente';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Me sinto muito doente';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Ainda estou doente, mas me sinto melhor';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'Eu estava me recuperando, mas agora me sinto mal de novo';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Sim, mas me sinto completamente recuperada.';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Seus sintomas';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Qual dos seguintes sintomas você tem?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Sem sintomas';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Realmente cansado';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Uma dor de cabeça';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Dor nas articulações e / ou músculos';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Dor de garganta';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Nariz entupido';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Ranhuras no nariz';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Tosse seca';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Tosse com expectoração';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Espirros';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Febre';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Calafrios';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Perda do paladar / olfato.';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Dificuldade para respirar';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Dor de estômago / diarréia';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Vômitos / náuseas';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Urticária ou alergias de pele';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Quanto lhe custou ou é difícil respirar? (2 = falta de ar leve, 8 = falta de ar severa)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Quão ruim você se sentiu ou em geral? (2 = apenas um pouco doente, 8 = gravemente doente / foi para o hospital)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'Quão ansioso / você está pelo COVID19? (2 = um pouco ansioso, 8 = muito ansioso)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'Quando você teve os primeiros sintomas?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Há quanto tempo você ficou doente / teve sintomas?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Alguns dias ou menos';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Menos do que uma semana';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Uma ou duas semanas';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'Duas ou três semanas';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Mais de três semanas';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Ainda estou doente!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Você achou que estava infectado pelo Coronavirus / COVID-19?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'De maneira nenhuma';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Uma pequena chance (~ 25% de certeza)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Uma oportunidade razoável (~ 50% de certeza)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Bastante seguro (~ 75% seguro)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Absolutamente convencido (~ 90% + certeza)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Sim, 100%: testei positivo';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Sim, 100%: fui tratado no hospital';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Sim, 100%: fui tratado em terapia intensiva';
lang.COVIDTESTING_PAGE_TITLE = 'Teste COVID';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'Foi realizado um teste de anticorpos para verificar se você possui / teve COVID-19?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'Não, eu não fiz um teste de anticorpos.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'Não, mas fiz um tipo diferente de teste';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Sim e eu fui positivo';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Sim, mas obtive um resultado negativo.';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Sim, mas não sei o resultado.';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'Em que data você fez o teste?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'Que tipo de teste de anticorpo você realizou para verificar se tinha / tinha COVID-19?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Teste rápido de sangue para anticorpos (imunoensaio de fluxo lateral)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Envio de sangue para análise laboratorial de anticorpos.';
lang.ANTIBODYTEST_PAGE_TITLE = 'Teste de anticorpos';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Como foi o resultado do seu teste?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'Apenas c';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C e IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C e IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM e IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Apenas IgG (sem C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Apenas IgM (sem C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Apenas IgG e IgM (sem C)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Nenhuma, nenhuma linha';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Nenhuma das acima';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Carregue uma foto do teste de anticorpos tirado diretamente de cima, com iluminação uniforme, para que o cassete de teste preencha a tela (como mostrado abaixo).';
lang.OTHERTESTS_PAGE_TITLE = 'Outros testes';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Você enviou sangue para um teste de anticorpos em laboratório?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'Você já realizou uma amostra de escarro / nasal / garganta para PCR?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Você fez um teste de antígeno viral?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Você já fez um exame de raio-X / CT?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Você já teve um diagnóstico médico?';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'Não fiz';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Resultado negativo';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Resultado positivo';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Resultado desconhecido';
lang.EMAILCONSENT_PAGE_TITLE = 'Última pergunta';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Você estaria interessado em aprender sobre um estudo, programa de teste ou julgamento para ajudar a confirmar se você se recuperou totalmente, doou plasma ou se é imune ao Coronavírus?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'Interessado, envie-me mais informações quando descobrir';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'Interessado, mas não me envie informações, por favor';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'Não, eu não estou interessado';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Por favor, deixe seu endereço de e-mail abaixo';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Evet';
lang.GLOBAL_LABEL_NO = 'Hayır';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'Bilmiyorum / hatırlayamıyorum';
lang.GLOBAL_LABEL_NEXT = 'Ileri';
lang.GLOBAL_LABEL_BACK = 'Geri';
lang.GLOBAL_LABEL_NONE = 'Yok';
lang.GLOBAL_LABEL_SUBMIT = 'Onayla';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'Gerekli';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'Lütfen geçerli bir e-posta adresi yazınız';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'Merhaba, Codi\'ye hoş geldiniz';
lang.MAINPAGE_PAGE_TEXT = 'Aşağıdaki seçeneklerden seçiniz:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'Anketi Cevaplayın';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'Video Talimatlarını İzle';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'Test Görüntüsünü Yükle';
lang.GENERALINFORMATION_PAGE_TITLE = 'Genel bilgi';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'Doğumda hangi cinsiyete atandın?';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'Erkek';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'Kadın';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'Diğer / Söylememeyi tercih ediyorum';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'Yaş grubunuz nedir?';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'Söylememeyi tercih ederim';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'Sağlığınız nasıl?';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'Çok zinde ve nadiren hastayım';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'Ortalamadan daha zinde ve sağlıklıyım';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'Ortalamadan daha az zinde ve sağlıklıyım';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'Hiç sağlıklı değilim';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'Aşağıdakilerden hangisine sahipsiniz/ hangileri size uygun?';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'Aşağıdakilerin hiçbiri';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'Hafif kalp rahatsızlığı';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'Kronik kalp rahatsızlığı';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'Hafif astım gibi hafif akciğer problemi';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'Kronik akciğer problemleri';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'Hafif veya sınırda diyabet';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'Kronik Diyabet';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'Son 5 sene içerisinde kemoterapi ile kanser tedavisi';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'Chemo ile geçmiş kanser tedavisi (5 yıldan fazla önce)';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'Azalmış böbrek fonksiyonu';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'Kronik karaciğer hastalığı';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'İmmünsüpresan ilaçlar alıyorum';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'hamileyim';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'Geçen yıl grip aşısı oldunuz mu?';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'Konum ve Seyahat';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'Şu an nerede yaşıyorsunuz?';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'Lütfen posta kodunuzun yalnızca ilk üç harfini veya rakamını girin.';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = '1 Ocak 2020\'den bu yana başka bir ülkeye seyahat ettiniz mi?';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'Evet, "karantinaden" önce';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'Evet, "karantina "\'deyken';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'Evet, "karantinaden" sonra';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'Lütfen tam olarak 3 karakter girin';
lang.TAKEPHOTO_PAGE_HEADER = 'Fotoğraf Sayfasını Çek';
lang.TAKEPHOTO_PAGE_BTN = 'Fotoğraf çek';
lang.TRAVELCONTINUED_PAGE_TITLE = 'Seyahat Devam';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = '1 Ocak 2020\'den beri hangi ülkeleri ziyaret ettiniz? (Birden fazla ülkeye gittiyseniz lütfen COVID-19\'u yakalayabileceğinizi veya onu yakalama riskinizin en yüksek olduğunu düşündüğünüz ülkeyi seçin)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'O ülkeye hangi tarihte girdiniz?';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'O ülkeden hangi tarihte ayrıldınız';
lang.EXPOSURERISK_PAGE_TITLE = 'Maruz kalma riski';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'Ülkenizin kilitlenmesi sırasında işe gittiniz mi?';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'Evet, ama günde birkaç kişiyle etkileşime girdim';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'Evet, günde birçok insanla etkileşime girdim';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'Evet ve bir sağlık ortamında günde birçok kişi / hasta ile etkileşime girdim.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'Covid-19’u evinizin dışında tutmaktan nasıl kaçındınız?';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'Her zaman insanlardan 6 ft / 2 m mesafeyi korudum.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'Her zaman maske / yüz kaplaması taktım.';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'Asla 30 dakikadan fazla iç mekanlarda/başkalarıyla aynı araçta geçirmedim';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'Sık sık ellerimi yıkadım ve nadiren yüzüme dokundum.';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'Evinizin dışında zaman geçirdiğniz biri kısa bir zaman sonra belirtiler gösterdi mi?';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'Onaylanmış COVID-19 (Pozitif tanı)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'Olası COVID-19 semptomları';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'Yukarıdakilerin hiçbiri';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'Kaç kişiyle yaşıyorsun? (Lütfen bir sayı yazın, örneğin 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'Evinizdeki herhangi birinin COVID-19\'u olabileceğini düşünüyor musunuz?';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'Evet, muhtemelen';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'Evet, kesinlikle (Pozitif test sonucu)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'Hasta Ev Arkadaşı';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'Birlikte yaşadığınız kaç kişinin olası COVID-19 semptomları vardı? Lütfen bir sayı yazın, ör. 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'Evinizdeki ilk kişi ne zaman hastalandı?';
lang.WEREYOUILL_PAGE_TITLE = 'Hiç hastalandın mı?';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'Ocak 2020\'den beri hiç hastalandınız mı? (Hayır derseniz, belirtiler sorulmaz)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'Hayır ben iyiyim. Ocak 2020\'den beri hiç hasta hissetmedim';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'Hasta hissetmeye başlıyorum';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'Şimdi oldukça hasta hissediyorum';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'Çok hasta hissediyorum';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'Hala hastayım ama daha iyi hissediyorum';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'İyileşiyordum, ama şimdi tekrar daha kötü hissediyorum';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'Evet, ama tamamen iyileştim';
lang.YOURSYMPTOMS_PAGE_TITLE = 'Belirtileriniz';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'Aşağıdaki belirtilerden hangisi sizde var/vardı?';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'Semptom yok';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'Gerçekten yorgun';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'Baş ağrısı';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'Be eklem ağrısı ve/veya kas ağrısı';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'Boğaz ağrısı';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'Burun tıkanıklığı';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'Burun akması';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'Kuru öksürük';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'Balgamlı öksürük';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'Hapşırma';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'Ateş';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'Titreme';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'Tat / koku alma duyusu kaybı';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'Nefes darlığı';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'Mide rahatsızlığı / ishal';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'Kusma / bulantı';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'Cilt döküntüsü';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'Nefes almak ne kadar zor, ya da nefes almak zor mu? (2 = hafif nefes darlığı, 8 = şiddetli nefes darlığı)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'Ne kadar kötüydünüz, ya da kendinizi genel olarak hissediyor musunuz? (2 = sadece hafif hasta, 8 = ağır hasta / hastaneye gitti)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'COVID19 hakkında ne kadar endişelisiniz? (2 = biraz endişeli, 8 = çok endişeli)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'İlk belirtiler ne zaman başladı?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'Ne zamandır hastasınız/ semptomlar var?';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'Birkaç gün veya daha az';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'Bir haftadan az';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'Bir ila iki hafta';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'İki ila üç hafta';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'Üç haftadan fazla';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'Hala hastayım!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'Sana Coronavirus / COVID-19 bulaşmış mı?';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'Olmaz';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'Küçük bir şans (~% 25 emin)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'Makul bir şans (~% 50 emin)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'Çok emin (~% 75 emin)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'Kesinlikle ikna oldu (~% 90 + emin)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'Evet,% 100: Pozitif test ettim';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'Evet,% 100: Hastanede tedavi gördüm';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'Evet,% 100: Yoğun bakımda tedavi gördüm';
lang.COVIDTESTING_PAGE_TITLE = 'COVID testi';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'COVID-19 olup olmadığını kontrol etmek için bir antikor testi yaptınız mı?';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'Hayır, antikor testi yapmadım';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'Hayır, ama farklı bir test yaptım';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'Evet ve pozitif test ettim';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'Evet, ama negatif test ettim';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'Evet, ama sonucu bilmiyorum';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'Testi hangi tarihte yaptınız?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'COVID-19 olup olmadığını görmek için ne tür antikor testi yaptınız?';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'Hızlı Antikor kan testi (Lateral flow immunoassay)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'Laboratuvar antikor testi için kan gönderildi';
lang.ANTIBODYTEST_PAGE_TITLE = 'Antikor testi';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'Test sonucunuz neye benziyordu?';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'Sadece C';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C ve IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C ve IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C, IgM ve IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'Yalnızca IgG (C yok)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'Yalnızca IgM (C yok)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'Yalnızca IgG ve IgM (C yok)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'Yok, çizgi yok';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'Yukarıdakilerin hiçbiri';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'Lütfen doğrudan yukarıdan alınan antikor testinin bir fotoğrafını, test kasetinin ekranı kaplaması için (hatta aşağıdaki gibi) bile ışıkla yükleyin.';
lang.OTHERTESTS_PAGE_TITLE = 'Diğer testler';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'Laboratuar antikor testi için kan gönderdiniz mi?';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'PCR için bir Balgam / Burun / Boğaz çubuğu kullandınız mı?';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'Viral bir Antijen testi yaptınız mı?';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'Bir X-ışını / BT tarama testi yaptınız mı?';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'Doktor teşhisinizi yaptı mı';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'Yapmadı';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'Olumsuz sonuç';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'Olumlu sonuç';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'Bilinmeyen sonuç';
lang.EMAILCONSENT_PAGE_TITLE = 'Son soru';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'Tamamen iyileşip iyileşmediğini doğrulamak, plazma bağışlamak veya Corona Virüsüne karşı bağışıklık kazanabilir misin diye yardımcı olmak için herhangi bir çalışma, test programı veya deneme hakkında bilmek ister misin?';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'İlgileniyorum, duyduğunuzda bana daha fazla bilgi gönderin';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'İlgileniyorum, ama lütfen bilgi yok';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'Hayır, ilgilenmiyorum';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'Lütfen e-posta adresinizi aşağıya bırakın';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

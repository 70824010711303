import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#B7D7D7',
      main: '#4E8084',
      dark: '#3A5A5A',

      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    common: {
      white: '#EFF4F5',
    },
    secondary: {
      light: '#FF9A77',
      main: '#ED694A',
      // dark: will be calculated from palette.secondary.main,
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#EFF4F5',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#4E8084',
      secondary: '#4F4F4F',
      disabled: '#B7D7D7',
    },
    action: {
      active: '#FF9A77',
      hover: '#ED4B0C',
    },
  },
  typography: {
    fontFamily: ['Josefin Sans', 'sans-serif'].join(','),
    fontSize: 16,
  },
});

export default theme;

import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'جی ہاں';
lang.GLOBAL_LABEL_NO = 'نہیں';
lang.GLOBAL_LABEL_DO_NOT_KNOW = 'مجھے نہیں معلوم / یاد نہیں';
lang.GLOBAL_LABEL_NEXT = 'اگلے';
lang.GLOBAL_LABEL_BACK = 'پیچھے';
lang.GLOBAL_LABEL_NONE = 'کوئی نہیں';
lang.GLOBAL_LABEL_SUBMIT = 'جمع کرائیں';
lang.GLOBAL_FORMVALIDATION_REQUIRED = 'ضروری ہے';
lang.GLOBAL_FORMVALIDATION_EMAIL = 'برائے مہربانی قابل قبول ای میل ایڈریس لکھیں';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = 'ہیلو ، کوڈی میں آپ کا استقبال ہے';
lang.MAINPAGE_PAGE_TEXT = 'براہ کرم ذیل میں سے اختیارات میں سے انتخاب کریں:';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = 'جوابی سوالیہ نشان';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = 'ویڈیو ہدایات دیکھیں';
lang.MAINPAGE_PAGE_TEST_IMAGE = 'ٹیسٹ کی تصویر اپ لوڈ کریں';
lang.GENERALINFORMATION_PAGE_TITLE = 'عام معلومات';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = 'آپ کو پیدائش کے وقت کونسا جنسی عمل تفویض کیا گیا تھا؟';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = 'مرد';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = 'عورت';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = 'دوسرے / نہ کہنا پسند کریں';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = 'آپ کی عمر کا گروپ کیا ہے؟';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = 'میں ترجیح نہیں دیتا ہوں';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = 'اپ کی صحت کیسی ہے؟';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = 'میں بہت فٹ اور شاذ و نادر ہی بیمار ہوں';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = 'میں اوسط سے زیادہ تیز اور صحت مند ہوں';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = 'میں اوسط سے کم فٹ اور صحت مند ہوں';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = 'میں بالکل بھی صحتمند نہیں ہوں';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = 'آپ کو مندرجہ ذیل میں سے کونسا آپ پر لاگو / لاگو ہوتا ہے؟';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = 'مندرجہ ذیل میں سے کوئی نہیں';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = 'دل کی ہلکی حالت';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = 'دائمی دل کی حالت';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = 'ہلکے دمے جیسے پھیپھڑوں کا ہلکا مسئلہ';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = 'پھیپھڑوں کی دائمی پریشانی';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = 'ہلکی یا بارڈر لائن ذیابیطس';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = 'دائمی ذیابیطس';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = 'حالیہ (پچھلے 5 سال) کیمو سے کینسر کا علاج';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = 'کیمو کے ذریعہ کینسر کا ماضی کا علاج (5 سال پہلے)';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = 'گردے کا کام کم ہوا';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = 'دائمی جگر کی بیماری';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = 'میں امیونوسوپرسینٹ دوائیں لیتا ہوں';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = 'میں حاملہ ہوں';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = 'کیا آپ کو پچھلے سال میں فلو کا شوٹ ہوا تھا؟';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = 'مقام اور سفر';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = 'آپ ابھی کہاں رہ رہے ہیں؟';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = 'براہ کرم اپنے پوسٹ کوڈ / زپ کوڈ کے صرف پہلے تین حرف یا نمبر درج کریں۔';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = 'کیا آپ یکم جنوری 2020 سے کسی دوسرے ممالک کا سفر کر چکے ہیں؟';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = 'ہاں ، اس سے پہلے کہ وہ "لاک ڈاؤن" میں ہوں';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = 'ہاں ، جب وہ "لاک ڈاؤن" میں تھے';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = 'ہاں ، "لاک ڈاؤن" سے باہر آنے کے بعد';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = 'براہ کرم بالکل 3 حرف درج کریں';
lang.TAKEPHOTO_PAGE_HEADER = 'فوٹو پیج لے لو';
lang.TAKEPHOTO_PAGE_BTN = 'تصویر لو';
lang.TRAVELCONTINUED_PAGE_TITLE = 'سفر جاری ہے';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = 'یکم جنوری 2020 سے آپ نے کون سے ممالک کا دورہ کیا؟ (اگر آپ متعدد ممالک گئے ہیں تو براہ کرم ایک ایسی جگہ منتخب کریں جہاں آپ کو لگتا ہے کہ آپ کو کوڈ 19 میں پکڑ سکتے ہیں یا اسے پکڑنے کا خطرہ سب سے زیادہ ہے)';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = 'آپ نے اس ملک میں کس تاریخ کو داخل کیا؟';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = 'آپ نے اس ملک کو کس تاریخ سے چھوڑا؟';
lang.EXPOSURERISK_PAGE_TITLE = 'نمائش کا خطرہ';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = 'کیا آپ اپنے ملک کے لاک ڈاؤن کے دوران کام پر گئے تھے؟';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = 'ہاں ، لیکن میں نے فی دن کچھ لوگوں کے ساتھ بات چیت کی';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = 'ہاں ، اور میں نے روزانہ بہت سارے لوگوں سے بات چیت کی';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = 'ہاں ، اور میں نے صحت کی دیکھ بھال کی ترتیب میں فی دن بہت سارے لوگوں / مریضوں کے ساتھ بات چیت کی۔';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = 'آپ نے اپنے گھر کے باہر COVID-19 کو پکڑنے سے کیا بچا؟';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = 'میں نے لوگوں سے ہمیشہ 6 فٹ / 2 میٹر کا فاصلہ رکھا۔';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = 'میں نے ہمیشہ ماسک / چہرہ ڈھانپ رکھا تھا۔';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = 'میں نے کبھی بھی 30 منٹ سے زیادہ گھروں میں / گاڑیوں میں دوسروں کے ساتھ نہیں گزارا۔';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = 'میں اکثر اپنے ہاتھ دھوتا تھا اور شاذ و نادر ہی اپنے چہرے کو چھوتا ہوں۔';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = 'آپ کے گھر سے باہر کیا آپ نے کسی کے ساتھ وقت گزارا جس کے پاس ترقی ہوئی یا اس کے فورا؟ بعد ...؟';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = 'تصدیق شدہ کوویڈ ۔19 (مثبت تشخیص)';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'ممکنہ کوویڈ ۔19 علامات';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = 'مندرجہ بالا میں سے کوئی بھی نہیں';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = 'آپ کتنے لوگوں کے ساتھ رہتے ہیں؟ (براہ کرم ایک نمبر ٹائپ کریں ، جیسے 2)';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = 'کیا آپ کو لگتا ہے کہ آپ کے گھر میں کسی کو کوڈ 19 ہوسکتا ہے؟';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = 'ہاں ، ممکن ہے';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = 'ہاں ، یقینی طور پر (مثبت امتحان کا نتیجہ)';
lang.ILLHOUSEMATES_PAGE_TITLE = 'بیمار گھر والے';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = 'آپ کتنے لوگوں کے ساتھ رہتے ہیں جن میں آپ کویوڈ 19 کے علامات تھے؟ براہ کرم ایک نمبر ٹائپ کریں ، جیسے۔ 2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = 'آپ کے گھر کا پہلا شخص کب بیمار ہوا؟';
lang.WEREYOUILL_PAGE_TITLE = 'کیا آپ کبھی بیمار تھے؟';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = 'کیا آپ جنوری 2020 کے بعد سے بالکل بیمار محسوس ہوئے ہیں؟ (اگر آپ نہیں کہتے ہیں تو ، آپ کو علامات کے بارے میں نہیں پوچھا جائے گا)';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = 'نہیں میں ٹھیک ہوں. میں جنوری 2020 کے بعد سے کسی بھی طرح سے بیمار نہیں ہوا ہوں';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = 'میں بیمار ہونے لگا ہوں';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = 'میں اب کافی بیمار ہوں';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = 'میں بہت بیمار ہو رہا ہوں';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = 'میں ابھی بھی بیمار ہوں لیکن بہتر محسوس ہورہا ہوں';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = 'میں صحت یاب ہو رہا تھا ، لیکن اب ایک بار پھر خرابی محسوس کررہا ہوں';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = 'ہاں ، لیکن میں مکمل صحت یاب ہو رہا ہوں';
lang.YOURSYMPTOMS_PAGE_TITLE = 'آپ کی علامات';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = 'آپ میں سے کون سی علامت / علامت ہے؟';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = 'کوئی علامت نہیں';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = 'واقعی تھکا ہوا';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = 'سر میں درد';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = 'اچی جوڑ اور / یا پٹھے';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = 'خراب گلا';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = 'بھیڑ ناک';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = 'ناک بہنا';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = 'خشک کھانسی';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = 'تھوک کے ساتھ کھانسی';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = 'چھینک آنا';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = 'بخار';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = 'سردی لگ رہی ہے';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = 'ذائقہ / بو کے احساس کا نقصان';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = 'سانس کی کمی';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = 'پیٹ میں خلل / اسہال';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = 'الٹی / متلی';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = 'جلد کی رگڑ';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = 'آپ کو کتنی مشکل کا سامنا کرنا پڑا ، یا آپ کو سانس لینا محسوس ہوتا ہے؟ (2 = سانس لینے میں ہلکی قلت ، 8 = سانس لینے میں شدید قلت)';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = 'آپ کو کتنا برا لگا ، یا آپ کو مجموعی طور پر محسوس ہورہا ہے؟ (2 = صرف تھوڑا سا بیمار ، 8 = شدید بیمار / اسپتال گئے)';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = 'آپ COVID19 کے بارے میں کتنے پریشان ہیں / ہیں؟ (2 = تھوڑا سا بے چین ، 8 = بہت پریشان)';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = 'جب آپ نے پہلی بار علامات کا تجربہ کیا؟';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = 'آپ کب تک بیمار محسوس ہوئے / علامات رکھتے ہیں؟';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = 'کچھ دن یا اس سے کم';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = 'ایک ہفتہ سے بھی کم';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = 'ایک سے دو ہفتے';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = 'دو سے تین ہفتے';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = 'تین ہفتوں سے زیادہ';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = 'میں ابھی بھی بیمار ہوں!';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = 'کیا آپ کو لگتا ہے کہ آپ کورونا وائرس / کوویڈ 19 سے متاثر تھے؟';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = 'ہرگز نہیں';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = 'ایک چھوٹا سا موقع (یقینی طور پر٪ 25٪)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = 'معقول موقع (sure 50٪ یقینی)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = 'بہت یقینی (75 sure یقینی)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = 'بالکل یقین (convinced 90٪ + یقینی)';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = 'ہاں ، 100٪: میں نے مثبت تجربہ کیا';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = 'ہاں ، 100٪: میرا اسپتال میں علاج کرایا گیا';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = 'ہاں ، 100٪: مجھ سے انتہائی نگہداشت کا علاج کیا گیا';
lang.COVIDTESTING_PAGE_TITLE = 'کوویڈ ٹیسٹنگ';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = 'کیا آپ نے یہ چیک کرنے کے لئے اینٹی باڈی ٹیسٹ کیا ہے کہ کیا آپ کے پاس COVID-19 ہے؟';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = 'نہیں ، میں نے اینٹی باڈی ٹیسٹ نہیں کیا ہے';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = 'نہیں ، لیکن میں نے ایک مختلف قسم کا ٹیسٹ کیا';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = 'ہاں ، اور میں نے مثبت تجربہ کیا';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = 'ہاں ، لیکن میں نے منفی تجربہ کیا';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = 'ہاں ، لیکن میں اس کا نتیجہ نہیں جانتا ہوں';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = 'آپ نے ٹیسٹ کس تاریخ میں کیا؟';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = 'آپ کو یہ دیکھنے کے لئے کس قسم کا اینٹی باڈی ٹیسٹ کیا کہ آپ کے پاس COVID-19 ہے / ہے؟';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = 'ریپڈ اینٹی باڈی بلڈ ٹیسٹ (پارشوئک بہاؤ اموناساسے)';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = 'لیبارٹری اینٹی باڈی ٹیسٹ کے لئے خون بھیجا گیا';
lang.ANTIBODYTEST_PAGE_TITLE = 'اینٹی باڈی ٹیسٹ';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = 'آپ کے امتحان کے نتائج کی طرح دکھائی دی؟';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = 'صرف سی';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'سی اور آئی جی جی';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'سی اور آئی جی ایم';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'سی ، آئی جی ایم اور آئی جی جی';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = 'صرف آئی جی جی (کوئی سی نہیں)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = 'صرف آئی جی ایم (کوئی سی نہیں)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = 'صرف آئی جی جی اور آئی جی ایم (کوئی سی نہیں)';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = 'کوئی نہیں ، کوئی لکیریں نہیں';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = 'مندرجہ بالا میں سے کوئی بھی نہیں';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = 'براہ کرم یہاں تک کہ روشنی کے ساتھ براہ راست اوپر سے لیئے گئے اینٹی باڈی ٹیسٹ کی تصویر اپلوڈ کریں تاکہ ٹیسٹ کیسٹ اسکرین کو بھر دے (جیسے نیچے دی گئی ہے)۔';
lang.OTHERTESTS_PAGE_TITLE = 'دوسرے ٹیسٹ';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = 'کیا آپ نے لیبارٹری اینٹی باڈی ٹیسٹ کے لئے خون بھیجا؟';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = 'کیا آپ نے پی سی آر کے لئے تھوک / ناک / گلے میں جھاڑو لگایا ہے؟';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = 'کیا آپ نے وائرل اینٹیجن ٹیسٹ کرایا ہے؟';
lang.OTHERTESTS_XRAYQUESTION_TEXT = 'کیا آپ کے پاس ایکسرے / سی ٹی ٹیسٹ کا اسکین ہوا ہے؟';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = 'کیا آپ کو ڈاکٹر کی تشخیص ہوئی ہے؟';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = 'نہیں کیا';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = 'منفی نتیجہ';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = 'مثبت نتیجہ';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = 'نامعلوم نتیجہ';
lang.EMAILCONSENT_PAGE_TITLE = 'حتمی سوال';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = 'کیا آپ کسی بھی مطالعے ، جانچ کے پروگراموں ، یا آزمائشوں کے بارے میں جاننے میں دلچسپی لیں گے کہ اگر آپ پوری طرح سے صحت یاب ہوچکے ہیں تو ، پلازما عطیہ کرنے کے ل confirm ، یا اگر آپ کورونا وائرس سے محفوظ رہ سکتے ہیں تو اس کی تصدیق میں مدد کریں گے۔';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = 'دلچسپی ہے ، جب آپ اس کے بارے میں سنتے ہیں تو مجھے مزید معلومات بھیجیں';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = 'دلچسپی ہے ، لیکن براہ کرم کوئی معلومات نہیں ہے';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = 'نہیں ، دلچسپی نہیں ہے';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = 'برائے کرم اپنا ای میل ایڈریس نیچے رکھیں';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;

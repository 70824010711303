import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = '是';
lang.GLOBAL_LABEL_NO = '不';
lang.GLOBAL_LABEL_DO_NOT_KNOW = '我不知道，我不记得了';
lang.GLOBAL_LABEL_NEXT = '下一个';
lang.GLOBAL_LABEL_BACK = '背后';
lang.GLOBAL_LABEL_NONE = '没有';
lang.GLOBAL_LABEL_SUBMIT = '提交';
lang.GLOBAL_FORMVALIDATION_REQUIRED = '需要';
lang.GLOBAL_FORMVALIDATION_EMAIL = '请输入有效的电子邮件地址';
lang.GLOBAL_ERROR_FORMSUBMISSION = 'There was an error submitting the form.';
lang.MAINPAGE_PAGE_TITLE = '您好，欢迎来到科迪';
lang.MAINPAGE_PAGE_TEXT = '从以下选项中选择：';
lang.MAINPAGE_PAGE_QUESTIONNAIRE = '回答问卷';
lang.MAINPAGE_PAGE_VIDEO_INSTRUCTIONS = '观看视频说明';
lang.MAINPAGE_PAGE_TEST_IMAGE = '上传测试图片';
lang.GENERALINFORMATION_PAGE_TITLE = '一般信息';
lang.GENERALINFORMATION_GENDERQUESTION_TEXT = '您出生时分配了哪种性别？';
lang.GENERALINFORMATION_GENDERQUESTION_MALE = '男';
lang.GENERALINFORMATION_GENDERQUESTION_FEMALE = '女';
lang.GENERALINFORMATION_GENDERQUESTION_OTHER = '其他/我不想说';
lang.GENERALINFORMATION_AGEQUESTION_TEXT = '您的年龄段是？';
lang.GENERALINFORMATION_AGEQUESTION_NOT_TO_SAY = '我不想说';
lang.GENERALINFORMATION_HEALTHQUESTION_TEXT = '你的身体如何？';
lang.GENERALINFORMATION_HEALTHQUESTION_RARELY_ILL = '我很健康，我很少生病';
lang.GENERALINFORMATION_HEALTHQUESTION_FITTER_AND_HEALTHIER = '我比一般人更健康';
lang.GENERALINFORMATION_HEALTHQUESTION_LESS_FIT_AND_HEALTHY = '我的健康状况不及一般人';
lang.GENERALINFORMATION_HEALTHQUESTION_NOT_HEALTHY = '我不太健康';
lang.GENERALINFORMATION_COMMONQUESTION_TEXT = '您拥有/适用于以下哪项？';
lang.GENERALINFORMATION_COMMONQUESTION_NONE = '以下都不是';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_HEART_CONDITION = '轻度心脏病';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_HEART_CONDITION = '慢性心脏病';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_LUNG_PROBLEM = '轻度肺部疾病，例如轻度哮喘';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_LUNG_PROBLEMS = '慢性肺问题';
lang.GENERALINFORMATION_COMMONQUESTION_MILD_DIABETES = '轻度或边缘性糖尿病';
lang.GENERALINFORMATION_COMMONQUESTION_CHRONIC_DIABETES = '慢性糖尿病';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_LAST_5_YEARS = '近期接受化疗的癌症治疗（最近5年）';
lang.GENERALINFORMATION_COMMONQUESTION_CHEMO_OVER_5_YEARS_AGO = '过去（5年前）使用化学疗法治疗癌症';
lang.GENERALINFORMATION_COMMONQUESTION_KIDNEY = '肾功能减退';
lang.GENERALINFORMATION_COMMONQUESTION_LIVER = '慢性肝病';
lang.GENERALINFORMATION_COMMONQUESTION_IMMUNOSUPPRESSANT = '我服用免疫抑制剂';
lang.GENERALINFORMATION_COMMONQUESTION_PREGNANT = '我怀孕了';
lang.GENERALINFORMATION_FLUQUESTION_TEXT = '你去年得了流感疫苗吗？';
lang.LOCATIONANDTRAVEL_PAGE_TITLE = '位置和旅行';
lang.LOCATIONANDTRAVEL_COUNTRYQUESTION_TEXT = '你现在住哪儿？';
lang.LOCATIONANDTRAVEL_ZIPCODEQUESTION_TEXT = '仅输入邮政编码的前三个字母或数字。';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_TEXT = '自2020年1月1日起您是否去过其他国家？';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_BEFORE_LOCKDOWN = '是的，在他们处于“紧急关机”之前';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_WHILE_LOCKDOWN = '是的，当他们处于“紧急关机”状态时';
lang.LOCATIONANDTRAVEL_TRAVELQUESTION_AFTER_LOCKDOWN = '是的，他们退出“紧急关机”之后';
lang.LOCATIONANDTRAVEL_FORMVALIDATION_THREECHARACTERS = '请输入正确的3个字符';
lang.TAKEPHOTO_PAGE_HEADER = '拍照页面';
lang.TAKEPHOTO_PAGE_BTN = '拍照';
lang.TRAVELCONTINUED_PAGE_TITLE = '连续旅行';
lang.TRAVELCONTINUED_VISITEDCOUNTRY_TEXT = '自2020年1月1日以来，您访问过哪些国家？ （如果您去了多个国家，请选择您认为可能捕获到COVID-19或您签约风险最高的国家）';
lang.TRAVELCONTINUED_VISITEDENTERDATE_TEXT = '您是什么时候进入那个国家的？';
lang.TRAVELCONTINUED_VISITEDLEAVEDATE_TEXT = '您是哪一天离开那个国家的？';
lang.EXPOSURERISK_PAGE_TITLE = '接触风险';
lang.EXPOSURERISK_GOTOWORKQUESTION_TEXT = '您是在国家禁闭期间上班的吗？';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_FEW_PEOPLE = '是的，但是我每天都很少有人互动。';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE = '是的，我每天与很多人互动。';
lang.EXPOSURERISK_GOTOWORKQUESTION_YES_MANY_PEOPLE_HEALTHCARE = '是的，我每天在医疗机构中与许多人/患者互动。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TEXT = '您如何避免将COVID-19带出家门？';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_DISTANCE = '我总是与人保持2m的距离。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_MASK = '我总是戴口罩/面罩。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_TIME_LIMITE = '我从未在室内/与他人共度30多分钟。';
lang.EXPOSURERISK_AVOIDCATCHINGQUESTION_HYGIENE = '我经常洗手，很少触摸我的脸。';
lang.EXPOSURERISK_SPENDTIMEQUESTION_TEXT = '在您的家外面，您是否有时间陪伴某个已经成长或不久之后的人？';
lang.EXPOSURERISK_SPENDTIMEQUESTION_CONFIRMED_COVID = '确认COVID-19（阳性诊断）';
lang.EXPOSURERISK_SPENDTIMEQUESTION_POSSIBLE_COVID = 'COVID-19的可能症状';
lang.EXPOSURERISK_SPENDTIMEQUESTION_NONE = '以上都不是';
lang.EXPOSURERISK_PEOPLELIVEWITHQUESTION_TEXT = '你和多少人住在一起？ （写一个数字，例如2）';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_TEXT = '您是否认为您家中有人可能感染过COVID-19？';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_POSSIBLE = '是的可能';
lang.EXPOSURERISK_POSSIBLEVIRUSQUESTION_YES_DEFINITELY = '是的，肯定（测试结果为阳性）';
lang.ILLHOUSEMATES_PAGE_TITLE = '生病的室友';
lang.ILLHOUSEMATES_NUMBERILLQUESTION_TEXT = '您与多少人共患过COVID-19症状？请写一个数字，例如2';
lang.ILLHOUSEMATES_FIRSTILLDATEQUESTION_TEXT = '第一个人何时在家生病？';
lang.WEREYOUILL_PAGE_TITLE = '你有生病吗';
lang.WEREYOUILL_WEREYOUILLQUESTION_TEXT = '自2020年1月起您是否感到难过？ （如果您拒绝，则不会询问您的症状）';
lang.WEREYOUILL_WEREYOUILLQUESTION_NOIMFINE = '我不是很好。自2020年1月以来我从未感到难过';
lang.WEREYOUILL_WEREYOUILLQUESTION_BEGINNINGFEELILL = '我开始感到恶心';
lang.WEREYOUILL_WEREYOUILLQUESTION_QUITEILL = '现在我好恶心';
lang.WEREYOUILL_WEREYOUILLQUESTION_VERYILL = '我很恶心';
lang.WEREYOUILL_WEREYOUILLQUESTION_ILLFEELINGBETTER = '我仍然生病，但我感觉好多了';
lang.WEREYOUILL_WEREYOUILLQUESTION_RECOVERINGFEELINGWORSE = '我当时正在康复，但现在我又感到恶心';
lang.WEREYOUILL_WEREYOUILLQUESTION_FULLYRECOVERD = '是的，但我感到完全康复。';
lang.YOURSYMPTOMS_PAGE_TITLE = '你的症状';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_TEXT = '您/您有以下哪些症状？';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_NOSYMPTOMS = '无症状';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_REALLYTIRED = '真的累了';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_AHEADACHE = '头痛';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_ACHYJOINTS = '关节和/或肌肉疼痛';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SORETHROAT = '咽喉痛';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CONGESTEDNOSE = '鼻塞';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_RUNNYNOSE = '鼻涕';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_DRYCOUGH = '干咳';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_COUGHSPUTUM = '咳嗽痰';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SNEEZING = '打喷嚏';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_FEVER = '发热';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_CHILLS = '颤抖';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_LOSSOFTASTE = '失去味觉/嗅觉。';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SHORTOFBREATH = '呼吸困难';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_STOMACHDISTURBANCE = '胃部不适/腹泻';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_VOMITING = '呕吐/恶心';
lang.YOURSYMPTOMS_WHICHSYMPTOMSQUESTION_SKINRASH = '荨麻疹或皮肤过敏';
lang.YOURSYMPTOMS_HOWHARDTOBREATHQUESTION_TEXT = '它花费了您多少钱或呼吸困难？ （2 =轻度呼吸急促，8 =严重呼吸急促）';
lang.YOURSYMPTOMS_HOWBADDIDYOUFEELQUESTION_TEXT = '您感觉如何或总体感觉如何？ （2 =仅轻微病，8 =重病/已住院）';
lang.YOURSYMPTOMS_HOWANXIOUSWEREYOUQUESTION_TEXT = '/您对COVID19的渴望如何？ （2 =有点焦虑，8 =非常焦虑）';
lang.YOURSYMPTOMS_FIRSTSYMPTOMSDATEQUESTION_TEXT = '您什么时候经历了最初的症状？';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TEXT = '您多长时间感到不适/有症状？';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_FEWDAYS = '几天或更短的时间';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_LESSTHANONEWEEK = '不到一周';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_ONETOTWOWEEKS = '一两个星期';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_TWOTOTHREEWEEKS = '两三个星期';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_MORETHANTHREEWEEKS = '三个多星期';
lang.YOURSYMPTOMS_HOWLONGWEREYOUILLQUESTION_IMSTILLILL = '我仍然生病！';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_TEXT = '您是否认为自己感染了冠状病毒/ COVID-19？';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_NOWAY = '没门';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_SMALLCHANCE = '几率很小（确定为25％）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_REASONABLECHANCE = '合理的机会（确定约50％）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_PRETTYSURE = '相当安全（〜75％安全）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_ABSOLUTELYCONVINCED = '绝对确信（〜90％+肯定）';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESTESTEDPOSITIVE = '是的，100％：我测试阳性';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESHOSPITAL = '是的，100％：我在医院接受过治疗';
lang.YOURSYMPTOMS_DOYOUTHINKYOUWEREINFECTEDQUESTION_YESINTENSIVECARE = '是的，100％：我接受了重症监护';
lang.COVIDTESTING_PAGE_TITLE = 'COVID测试';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_TEXT = '是否进行过抗体测试以检查您是否患有COVID-19？';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NO = '不，我还没有做抗体测试。';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_NODIFFERENTTEST = '否，但是我做了另一种类型的测试';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESPOSITIVE = '是的，我很肯定';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESNEGATIVE = '是的，但是我得到了负面结果。';
lang.COVIDTESTING_DONEANTIBODYTESTQUESTION_YESDONTKNOW = '是的，但我不知道结果。';
lang.COVIDTESTING_ANTIBODYTESTDATEQUESTION_TEXT = '你什么时候参加考试的？';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_TEXT = '您进行了哪种类型的抗体测试，以查看您是否已拥有COVID-19？';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_RAPIDANTIBODY = '快速血液检测抗体（侧向流免疫测定）';
lang.COVIDTESTING_ANTIBODYTESTKINDQUESTION_SENTBLOODLABTEST = '送血进行实验室抗体分析。';
lang.ANTIBODYTEST_PAGE_TITLE = '抗体测试';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_TEXT = '您的测试结果是什么样的？';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C = '只有C';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGG = 'C和IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM = 'C和IgM';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_C_IGM_IGG = 'C，IgM和IgG';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG = '仅IgG（无C）';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGM = '仅IgM（无C）';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_IGG_IGM = '仅IgG和IgM（无C）';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NOLINES = '无，无行';
lang.ANITBODYTEST_ANTIBODYTESTRESULTQUESTION_NONEOFABOVE = '以上都不是';
lang.ANITBODYTEST_UPLOADPHOTOQUESTION_TEXT = '上载直接从上方拍摄的抗体测试照片，并在光线均匀的情况下使测试盒充满屏幕（如下所示）。';
lang.OTHERTESTS_PAGE_TITLE = '其他测试';
lang.OTHERTESTS_LABANTIBODYTESTQUESTION_TEXT = '您是否送血进行实验室抗体测试？';
lang.OTHERTESTS_SWABTESTQUESTION_TEXT = '您是否进行了痰/鼻/喉样本的PCR？';
lang.OTHERTESTS_VIRALANTIGENQUESTION_TEXT = '你做过病毒抗原测试吗？';
lang.OTHERTESTS_XRAYQUESTION_TEXT = '您进行过X射线/ CT检查吗？';
lang.OTHERTESTS_DOCTORSDIAGNOSISQUESTION_TEXT = '你有医疗诊断吗？';
lang.OTHERTESTS_DIDNOTDOOPTION_TEXT = '没做';
lang.OTHERTESTS_NEGATIVEOPTION_TEXT = '负结果';
lang.OTHERTESTS_POSITIVEOPTION_TEXT = '积极的结果';
lang.OTHERTESTS_UNKNOWNOPTION_TEXT = '未知结果';
lang.EMAILCONSENT_PAGE_TITLE = '最后一个问题';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_TEXT = '您是否有兴趣了解一项研究，测试计划或试验，以帮助确认您是否已完全康复，捐献血浆或对冠状病毒免疫？';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDMOREINFO = '有兴趣的话，请在发现时给我发送更多信息';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_INTERESTEDNOINFO = '有兴趣，但请不要向我发送信息';
lang.EMAILCONSENT_EMAILCONSENTQUESTION_NOTINTERESTED = '不，我不感兴趣';
lang.EMAILCONSENT_EMAILADDRESSQUESTION_TEXT = '请在下面留下您的电子邮件地址';
lang.RESULT_PAGE_TITLE = 'Thank You!';
lang.RESULT_PAGE_TEXT = 'You will receive your results by email shortly.';

export default lang;
